import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Update} from '@ngrx/entity';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../core/_base/crud';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {select, Store} from '@ngrx/store';
import {map, take, takeUntil} from 'rxjs/operators';
import {AppState} from '../../../../../core/reducers';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {each} from 'lodash';
import {skip} from 'rxjs/operators';
import {
    AgencyModel,
    AgencyOnServerCreated,
    AgencyUpdated,
    CityModel,
    CountryModel,
    selectAllCountries,
    selectCitiesByCountryId,
    selectLastCreatedAgencyId,
    AllCountriesRequested, AllCitiesRequested, RegNumberModel
} from '../../../../../core/admin';
import {AllUsersOfRoleRequested, selectValuersInStore, User} from '../../../../../core/auth';
// import {AuthService} from '../../../../../core/auth/_services';
// import {ImageViewerDialogComponent} from '../../../apps/assignment/audit/_sub/image_viewer/image-viewer.dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {UploadDocumentComponent} from '../../../shared_components/upload-document/upload-document.component';
import {ImageViewerDialogComponent} from '../../../shared_components/image_viewer/image-viewer.dialog.component';
import { AgencyCriteriaService } from 'src/app/core/admin/_services/agency-criteria.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { awConst } from 'src/app/app.constants';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { AllFloorNumberingSchemesRequested, FloorNumberingScheme, selectAllFloorNumberingSchemes } from 'src/app/core/linked-tables';
import { VposTableComponent } from '../_subs/vpos-table/vpos-table.component';
import { OtherDocumentsTableComponent } from '../_subs/other-documents-table/other-documents-table.component';
import { AdditionalTermsTableComponent } from '../_subs/additional-terms-table/additional-terms-table.component';
import { ReportDesignsTableComponent } from '../_subs/report-designs-table/report-designs-table.component';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';
import { CountryEditComponent } from '../../countries/country-edit/country-edit.component';
import { CityEditComponent } from '../../countries/cities/city-edit/city-edit.component';
import { PostValuationDeliveryServiceComponent } from '../../../shared_components/post-valuation-delivery-service/post-valuation-delivery-service.component';
import { AccessibilityHelp, AutoLink, Autosave, Bold, ClassicEditor, Essentials, Italic, Link, Paragraph, SelectAll, Underline, Undo } from 'ckeditor5';

@Component({
    selector: 'kt-agency-edit',
    templateUrl: './agency-edit.component.html',
    styleUrls: ['./agency-edit.component.css'],
})

export class AgencyEditComponent implements OnInit, OnDestroy {

  @ViewChild('paymentInfo') paymentInfo;
  @ViewChild('disclaimer') disclaimer;
  @ViewChild('fileInputImg') fileInputImg: ElementRef;
  @ViewChild(UploadDocumentComponent)
  private uploadDocumentComponent: UploadDocumentComponent;
  @ViewChild(VposTableComponent, {static: false}) vposTableComponent: VposTableComponent;
  @ViewChild(OtherDocumentsTableComponent, {static: false}) otherDocumentsComponent: OtherDocumentsTableComponent;
  @ViewChild(AdditionalTermsTableComponent, {static: false}) additionalTermTableComponent: AdditionalTermsTableComponent;
  @ViewChild(ReportDesignsTableComponent, {static: false}) designtableComponent: ReportDesignsTableComponent;
  @ViewChild(PostValuationDeliveryServiceComponent, {static: false}) postValuationDeliveryServiceComponent: PostValuationDeliveryServiceComponent;
  
  awConst = awConst;

  agency: AgencyModel;
  oldAgency: AgencyModel;

  agencyForm: UntypedFormGroup;
  hasFormErrors = false;
  autoInsert = false;
  readonly = false;

  // Countries
  allCountries: CountryModel[] = [];
  cityOfCountry: CityModel[] = [];
  valuers: User[] = [];


  progressValue = 0;
  progress$: Observable<number>;
  progressSubject = new BehaviorSubject<number>(0);
  regNumbersSubject: BehaviorSubject<RegNumberModel[]> = new BehaviorSubject([]);
  // Private properties
  private componentSubscriptions: Subscription;
  private subscriptions: Subscription[] = [];

  fileType = '.png, .jpg, jpeg, application/pdf';

  displayedColumns = ['type', 'name', 'file_name', 'actions'];
  dataSource = new MatTableDataSource();

  agencyFileRows = [
      {
          name: 'Report Logo Picture',
          type: 'jpeg',
          field: 'picture'
      }, {
          name: 'Default Report Cover Picture',
          type: 'jpeg',
          field: 'cover_picture'
      }
  ];

  validationMessages: any = {
      email: [
          {type: 'required', message: 'Email is ', decorated: 'required'},
          {type: 'email', message: 'Enter a valid ', decorated: 'email'}
      ]
  };
  emailRegex: any = '^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$';
  private selectedFileType: string;

  // criteria$: BehaviorSubject<IAgencyCriterion[]> = new BehaviorSubject([]);
  uploadedFiles$ = new BehaviorSubject([]);
  res$ = new BehaviorSubject(null);
  floorNumberingSchemes: FloorNumberingScheme[] = [];

  selectedTab = 0;
  selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
  tabHeaders: TabHeader[] = [
    {label: 'General Information', disabled: of(false)},
    {label: 'Valuation Matter', disabled: of(false)},
    {label: 'Legal', disabled: of(false)},
    {label: 'Payment', disabled: of(false)},
    {label: 'Invoicing', disabled: of(false)},
    {label: 'Design', disabled: of(false)},
    {label: 'Settings', disabled: of(false)},
  ]
  error:any = {
    msg: ' Missing Fields in total:',
    fields: []
  }

  public PaymentEditor = ClassicEditor
  public DisclaimerEditor = ClassicEditor
  public editorConfig = {
    plugins: [Essentials, Bold, Italic, Link, Paragraph, SelectAll, Undo, Autosave, AutoLink, AccessibilityHelp, Underline],
    toolbar: ['bold', 'italic', 'underline']
  }
  paymentInfoData = ''
  disclaimerData = '';

  private _onDestroy$: Subject<void> = new Subject();

  canEdit$ = this.store.pipe(
    select(hasPermission(['agency:crud']))
  )

  /**
   * Component constructor
   *
   * @param activatedRoute: ActivatedRoute
   * @param router: Router
   * @param fb: FormBuilder
   * @param location: Location
   * @param subheaderService: SubheaderService,
   * @param authService: AuthService,
   * @param dialog: MatDialog,
   * @param typesUtilsService: TypesUtilsService
   * @param layoutUtilsService: LayoutUtilsService,
   * @param store: Store<AppState>
   * @param layoutConfigService
   */
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder,
              private location: Location,
              private subheaderService: SubheaderService,
              private authService: MadAuthService,
              private dialog: MatDialog,
              public typesUtilsService: TypesUtilsService,
              private layoutUtilsService: LayoutUtilsService,
              private store: Store<AppState>,
              private layoutConfigService: LayoutConfigService,
              private translate: TranslateService,
              private criteriaService: AgencyCriteriaService) {
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.agencyFileRows);
    this.progress$ = this.progressSubject.asObservable();
    const routeSubscription = this.activatedRoute.params.subscribe(params => {
      const id = params.id;
      if (id && id > 0) {
        this.activatedRoute.data.subscribe(data => {
          const res = data.data;
          this.oldAgency = Object.assign({}, res.data);
          this.agency = Object.assign({}, this.oldAgency);
          this.initAgency();
        });
      } else {
        this.agency = new AgencyModel();
        this.agency.clear();
        this.oldAgency = Object.assign({}, this.agency);
        this.initAgency();
      }
    });
    this.regNumbersSubject.next(this.agency.reg_numbers);
    this.subscriptions.push(routeSubscription);

    this.store.dispatch(new AllCountriesRequested());
    this.store.dispatch(new AllCitiesRequested());
    const countrySubscription = this.store.pipe(select(selectAllCountries)).subscribe((res: CountryModel[]) => {
      this.allCountries = [];
      each(res, (_country: CountryModel) => {
        this.allCountries.push(_country);
      });
    });
    this.subscriptions.push(countrySubscription);

    this.store.dispatch(new AllUsersOfRoleRequested({roleId: 0}));
    const valuerSubscription = this.store.pipe(
      skip(1),
      select(selectValuersInStore))
      .subscribe(res => {
        this.valuers = [];
        if (res) {
          this.valuers = res.items;
        }
      });
    this.subscriptions.push(valuerSubscription);

    this.store.dispatch(new AllFloorNumberingSchemesRequested());
    const floorNumberingSub = this.store.pipe(
      select(selectAllFloorNumberingSchemes)
    ).subscribe(res => {
      this.floorNumberingSchemes = res ? res : [];
    });
    this.subscriptions.push(floorNumberingSub);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this.componentSubscriptions) {
      this.componentSubscriptions.unsubscribe();
    }
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }

  initAgency() {
    this.createForm();
    if (!this.agency.id) {
      this.subheaderService.setTitle('New Agency');
      this.subheaderService.setBreadcrumbs([
        {title: 'Admin management', page: `../default/admin-management`},
        {title: 'Agencys', page: `../default/admin-management/agencys`},
        {title: 'Add Agency', page: `../default/admin-management/agencys/add`}
      ]);
      return;
    }
    this.subheaderService.setTitle('Edit Agency');
    this.subheaderService.setBreadcrumbs([
      {title: 'Admin management', page: `../default/admin-management`},
      {title: 'Agencys', page: `../default/admin-management/agencys`},
      {title: 'Add Agency', page: `../default/ecommerce/products/edit`}
    ]);
  }

  createForm() {
    this.autoInsert = this.agency.auto_insert;
    this.agencyForm = this.fb.group({
      name: [this.agency.name, Validators.required],
      legal_name: [this.agency.legal_name, Validators.required],
      registration_number: [this.agency.registration_number],

      website: [this.agency.website],
      email: [this.agency.email, Validators.required],
      phone: [this.agency.phone, Validators.required],
      address: [this.agency.address, Validators.required],
      
      zip_code: [this.agency.zip_code],
      country_id: ['' + this.agency.country_id, Validators.required],
      city_id: ['' + this.agency.city_id, Validators.required],

      description: [this.agency.description, Validators.required],
      source_of_data: [this.agency.source_of_data, Validators.required],
      complain_of_procedure: [this.agency.complain_of_procedure],

      auto_insert: [this.autoInsert],
      payment_info: [this.agency.payment_info],
      disclaimer: [this.agency.disclaimer],

      floor_numbering_scheme_id: [this.agency.floor_numbering_scheme_id, Validators.required],

      rics_regulated: [this.agency.rics_regulated],
      vpo_regulated: [this.agency.vpo_regulated]
    });

    this.paymentInfoData = this.agency.payment_info;
    this.disclaimerData = this.agency.disclaimer;

    // Form Changes
    this.agencyForm.controls.rics_regulated.valueChanges.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(val => {
      if (val) {
        this.agencyForm.controls.registration_number.setValidators([Validators.required]);
        this.agencyForm.controls.complain_of_procedure.setValidators([Validators.required]);
      } else {
        this.agencyForm.controls.registration_number.clearValidators();
        this.agencyForm.controls.complain_of_procedure.clearValidators();
      }
      this.agencyForm.controls.registration_number.updateValueAndValidity();
      this.agencyForm.controls.complain_of_procedure.updateValueAndValidity();
    })

    if (this.agency.country_id > 0) {
      this.selectCountry();
    }
  }

  selectCountry() {
    this.store.pipe(select(selectCitiesByCountryId(this.agency.country_id))).subscribe((res: CityModel[]) => {
      this.cityOfCountry = res;
    });
  }

  /**
   * Returns page title
   */
  getComponentTitle(): Observable<string> {
    if (this.agency && this.agency.id > 0) {
      return combineLatest([
        of(this.translate.instant('AGENCY.FORM.TITLE.EDIT', {name: this.agency.name})),
        this.canEdit$,
      ]).pipe(map(([title, hasPermissions]) => {
        return `${title} ${hasPermissions ? '' : '(View Only)'}`.trim();
      }));
    }
    return of(this.translate.instant('AGENCY.FORM.TITLE.NEW'));
  }

  /**
   * On Submit
   */
  onSubmit() {
    let errorFields = [];
    this.hasFormErrors = false;
    const controls = this.agencyForm.controls;
    /** check form */
    if (this.agencyForm.invalid) {
      Object.keys(controls).forEach(controlName => {
        if (!controls[controlName].valid) {
          errorFields = [...errorFields, controlName];
        }
        controls[controlName].markAsTouched()
      })

      this.hasFormErrors = true;
    }

    if (this.agency.country_id) {
      const item = this.allCountries.find(e => e.id == this.agency.country_id);
      this.agency.country_name = item ? item.name : '';
    }

    if (this.agency.city_id) {
      const item = this.cityOfCountry.find(e => e.id == this.agency.city_id);
      this.agency.city_name = item ? item.name : '';
    }

    this.agency.auto_insert = this.autoInsert;
    this.agency.floor_numbering_scheme_id = controls.floor_numbering_scheme_id.value;

    // this.agency.certificates = this.uploadedFiles$.value;
    // this.res$.pipe(take(1)).subscribe(value => {
    //   if (value) {
    //     this.agency.path = value.success;
    //   }
    // })
    if (this.postValuationDeliveryServiceComponent) {
      if (this.postValuationDeliveryServiceComponent.validate()) {
        this.hasFormErrors = true;
        errorFields = [...errorFields, ...this.postValuationDeliveryServiceComponent.errorFields];
      }
    }

    if (this.hasFormErrors) {
      this.error.fields = errorFields;
      return;
    }

    if (this.postValuationDeliveryServiceComponent) {
      this.agency.postValuationDeliveryServicetData = this.postValuationDeliveryServiceComponent.getData();
    }

    this.agency.payment_info = controls.payment_info.value;
    this.agency.disclaimer = controls.disclaimer.value;
    this.agency.reg_numbers = this.regNumbersSubject.value;
    
    this.agency.rics_regulated = controls.rics_regulated.value;
    this.agency.vpo_regulated = controls.vpo_regulated.value;
    if (this.vposTableComponent) {
      this.agency.vpos = this.vposTableComponent.getVpos();
    }
    if (this.otherDocumentsComponent) {
      const odData = this.otherDocumentsComponent.getData();
      this.agency.certificates = odData.document;
      this.agency.path = odData.path;
    }
    if (this.additionalTermTableComponent) {
      this.agency.additionalTerms = this.additionalTermTableComponent.getData();
    }
    if (this.designtableComponent) {
      this.agency.reportSchemes = this.designtableComponent.getData();
    }

    if (this.agency.id > 0) {
      this.updateAgency(this.agency);
    } else {
      this.createAgency(this.agency);
    }
  }

  /**
   * Update agency
   *
   * @param _agency: AgencyModel
   */
  updateAgency(_agency: AgencyModel) {
    const updateAgency: Update<AgencyModel> = {
      id: _agency.id,
      changes: _agency
    };
    this.store.dispatch(new AgencyUpdated({
      partialItem: updateAgency,
      item: _agency,
    }));

    this.oldAgency = _agency;
    this.layoutUtilsService.showActionNotification(
      this.translate.instant('GENERAL.MESSAGE.SAVE_CHANGES'),
      MessageType.Update, 3000, true, false);
  }

  /**
   * Create agency
   *
   * @param _agency: AgencyModel
   */
  createAgency(_agency: AgencyModel) {
    this.store.dispatch(new AgencyOnServerCreated({
      item: _agency,
    }));
    this.oldAgency = _agency;
    this.componentSubscriptions = this.store.pipe(
      select(selectLastCreatedAgencyId)
    ).subscribe(res => {
      if (!res) {
        return;
      }
      this.location.back();
    });
  }

  /** Alect Close event */
  onAlertClose($event) {
    this.hasFormErrors = false;
  }

  back() {
    this.location.back();
  }

  isFormValid() {
    if (this.agencyForm) {
      return !this.agencyForm.invalid;
    }
    return false;
  }

  changed() {
    this.autoInsert = !this.autoInsert;
  }

  addFiles(type) {
    switch (type) {
      case 'picture':
        this.fileInputImg.nativeElement.click();
        break;
      case 'cover_picture':
        this.fileInputImg.nativeElement.click();
        break;
      default:
        break;

    }
    this.selectedFileType = type;
  }

  canDeactivate() {
    if (this.discard()) {
      if (window.confirm(this.translate.instant('GENERAL.MESSAGE.LOST_CHANGES'))) {
        return true;
      } else {
        // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
        const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
        const currentUrl = currentUrlTree.toString();
        this.location.go(currentUrl);
        // ---------work around end-----------------
        return false;
      }
    }
    return true;
  }

  discard() {
    if (this.agency.name != this.oldAgency.name ||
      this.agency.legal_name != this.oldAgency.legal_name ||
      this.agency.registration_number != this.oldAgency.registration_number ||

      this.agency.website != this.oldAgency.website ||
      this.agency.email != this.oldAgency.email ||
      this.agency.phone != this.oldAgency.phone ||

      this.agency.address != this.oldAgency.address ||

      this.agency.zip_code != this.oldAgency.zip_code ||
      this.agency.country_id != this.oldAgency.country_id ||
      this.agency.city_id != this.oldAgency.city_id ||

      this.agency.description != this.oldAgency.description ||
      this.agency.source_of_data != this.oldAgency.source_of_data ||
      this.agency.complain_of_procedure != this.oldAgency.complain_of_procedure) {

      // this.agency.ivs_standard_id != this.oldAgency.ivs_standard_id ||
      // this.agency.rics_standard_id != this.oldAgency.rics_standard_id ||
      // this.agency.ipms_standard_id != this.oldAgency.ipms_standard_id
      return true;
    } else {
      return false;
    }
  }


  // upload
  uploadFile(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.agency[this.selectedFileType] = file.name;
      this.upload(file, file.name, this.selectedFileType);
    }
  }

  upload(file: File, name: string, path: string) {
    const formData = new FormData();
    formData.append('file', file, name);
    formData.append('path', path);

    this.progressSubject.next(1);
    this.authService.upload(formData).subscribe(
      (res) => {
        if (res.status) {
          if (res.status === 'progress') {
            this.progressSubject.next(res.message);
          }
          if (res.status === 'done') {


            switch (path) {
              case 'picture':
                this.agency.picture = res.filePath;
                break;
              case 'cover_picture':
                this.agency.cover_picture = res.filePath;
                break;
              default:
                break;
            }

            this.fileInputImg.nativeElement.value = '';
            this.progressSubject.next(0);
          }
        }
      },
      (err) => this.error = err
    );
  }

  deleteUploadedFile(field) {
    this.agency[field] = '';
  }

  previewUploadedFile(type) {
    switch (type) {
      case 'picture':
        this.dialog.open(ImageViewerDialogComponent, {
          data: {
            picture: this.agency.picture,
            type: 'jpg'
          }
        });
        break;
      case 'cover_picture':
        this.dialog.open(ImageViewerDialogComponent, {
          data: {
            picture: this.agency.cover_picture,
            type: 'jpg'
          }
        });
        break;
      default :
        break;
    }
  }

  setIcon(fname) {
    const type = this.getFileType(fname);
    let ext = 'file';
    switch (type) {
      case 'png':
        ext = 'png';
        break;
      case 'jpeg':
        ext = 'jpg';
        break;
      case 'jpg':
        ext = 'jpg';
        break;
      case 'gif':
        ext = 'gif';
        break;
      case 'pdf':
        ext = 'pdf';
        break;
      case 'xls':
        ext = 'xls';
        break;
      case 'xlsx':
        ext = 'xls';
        break;
      case 'rtf':
        ext = 'doc';
        break;
      case 'doc':
        ext = 'doc';
        break;
      case 'docx':
        ext = 'doc';
        break;
      case 'mp3':
        ext = 'mp3';
        break;
    }
    return './assets/media/files-alt/' + ext + '.svg';
  }

  getFileType(name) {
    return name.substring(name.indexOf('.') + 1, name.length);
  }

  getFileName(name) {
    return (this.agency[name] && this.agency[name].length > 0) ? this.agency[name].split('/').pop().replace(/^\d+_/, '') : awConst.NOFILE;
  }

  registrationChanged(e) {
    if (e) {
      this.agencyForm.controls.complain_of_procedure.setValidators(Validators.required);
      this.agencyForm.controls.complain_of_procedure.markAsTouched();
    } else {
      this.agencyForm.controls.complain_of_procedure.setValidators(null);
    }
    this.agencyForm.controls.complain_of_procedure.updateValueAndValidity();
  }
  onTabChanged($event) {
    const activeIndex = $event.index;
  }
  onTabChange(index: number) {
    this.selectedTab = index;
  }
  changeTab(section: string) {
    const section_tab = [
      {sections: ['Information'], tab_index: 0},
      {sections: ['Memberships', 'Descriptions'], tab_index: 1},
      {sections: ['Payment'], tab_index: 3},
      {sections: ['Settings'], tab_index: 6},
    ]
    const active_Tab = section_tab.find(item => item.sections.includes(section));
    this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
    this.selectedtabchange.next(this.selectedTab);
  }
  erroTabMap() {
    return this.typesUtilsService.getAgencyTabErrorMap(this.error.fields, this.tabHeaders);
  }
  onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
    this.hasFormErrors = obj.hasFormErrors;
  }

  addCountry() {
    const dialogRef = this.dialog.open(CountryEditComponent, {data: {id: undefined}})
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return
      }
      this.agencyForm.controls.country_id.setValue(res.id)
      this.agencyForm.controls.country_id.updateValueAndValidity()
    })
  }

  addCity() {
    const newCity = new CityModel()
    newCity.clear()
    newCity.country_id = this.agencyForm.controls.country_id.value
    const country = this.allCountries.find(c => c.id == newCity.country_id)
    newCity.country_name = country ? country.name : null
    const dialogRef = this.dialog.open(CityEditComponent, {data: {city: newCity}})
    dialogRef.afterClosed().pipe(takeUntil(this._onDestroy$)).subscribe(res => {
      if (!res) {
        return;
      }
      this.agencyForm.controls.city_id.setValue(res.id)
      this.agencyForm.controls.city_id.updateValueAndValidity()
      this.selectCountry()
    })
  }
}
