import { createSelector } from "@ngrx/store";
import { selectFeature } from './_base'
import { SRERStatus, ValuationSRERModel } from "src/app/core/valuation/_models/valuation-srer.model";
import { each } from "lodash";

export const selectAllSrer = createSelector(
  selectFeature,
  _state => {
    const items: ValuationSRERModel[] = [];
    each(_state.srer.entities, el => items.push(el));
    return items;
  }
)
export const selectSRERSpecialAssumption = createSelector(
  selectAllSrer,
  sers => {
    return sers.map(s => s.specialAssumption).filter(a => a.status == SRERStatus.Amended);
  }
)