import { HeadlineRent, HeadlineToEffectiveMeta } from "../../types";
import { calculateCashFlowMethod } from "./cash-flow";
import { calculateStraightLineMethod } from "./straight-line-method";
import { calculateTimeValueOfMoney } from "./time-value-of-money";

export function headlineToEffective(headlineRent: HeadlineRent, meta: HeadlineToEffectiveMeta) {
  if (!meta) return undefined
  if (meta.mainMethod === 'cash-flow') {
    const result = calculateCashFlowMethod(headlineRent, meta.growthRate, meta.targetRate)
    if (meta.main) {
      return result.results.effectiveRent
    } else if (meta.index !== undefined) {
      return result.results.effectiveRents[meta.index]?.value
    }
    return undefined
  }

  if (meta.mainMethod === 'time-value-of-money') {
    const result = calculateTimeValueOfMoney(headlineRent, headlineRent.size, meta.subMethod, meta.capitalRate, meta.targetRate)
    return result.effectiveRent
  }

  if (meta.mainMethod === 'straight-line-method') {
    const result = calculateStraightLineMethod(headlineRent, headlineRent.size)
    return result.effectiveRent
  }

  return undefined
}