export class ValuationAssumptionDeparture {
  id: number;
  front_id: number;
  valuation_id: number;
  ref_id: number;
  type: Type;
  source: Source;
  input: string;
  status: Status;
  justification: string;
  default_assumption_id: number = null;
  special_assumption_id: number = null;
  departure_id: number = null;
}

export enum Type {
  DefaultAssumption = 1,
  SpecialAssumption = 2,
  Departure = 3
}

export enum Source {
  ToE = 1,
  Input = 2,
}

export enum Status {
  Valid = 1,
  Amended = 2,
  Removed = 3
}

export const getType = (type: Type): string => {
  if (type === Type.DefaultAssumption) {
    return 'Assumption'
  } else if (type === Type.SpecialAssumption) {
    return 'Special Assumption'
  } else {
    return 'Departure'
  }
}