import { createAction, props } from "@ngrx/store"
import { AssetClassModel } from "src/app/core/asset_class"
import { ValuationVpga10Matter } from "src/app/core/valuation/_models/vpga10-matter.model"

export const addVpga10Matter = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Add VPG10 Matter',
  props<{
    data: ValuationVpga10Matter
  }>()
)

export const editVpga10Matter = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Edit VPG10 Matter',
  props<{
    data: ValuationVpga10Matter
  }>()
)

export const removeToeLocalVpga10Matter = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Remove ToE Local VPG10 Matter',
  props<{
    data: ValuationVpga10Matter
    tp: AssetClassModel,
    justification: string
  }>()
)

export const removeInputVpga10Matter = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Remove Input VPG10 Matter',
  props<{
    id: number
  }>()
)

export const restoreLocalVpga10Matter = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Restore Local VPG10 Matter',
  props<{
    data: ValuationVpga10Matter
    tp: AssetClassModel
  }>()
)
