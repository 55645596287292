import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { environment } from "src/environments/environment";

@Component({
    selector: 'kt-redirect',
    template: `
<div class="kt-login-head kt_login_wrapper">
  <!-- <span class="kt-login-signup-label">Don't have an account yet?</span>&nbsp;&nbsp;
  <a href="javascript:;" routerLink="/mad-auth/register" class="kt-link kt-login-signup-link">
    Sign Up
  </a> -->
</div>

<div class="kt-login-body">
  <div class="kt-login-form">
    <h1>
      <a href="https://www.interval-soft.com/" title="Login Page">
        Post Login Page
      </a>
    </h1>
    <!--<div class="mad-login-form">
      <div id="login-embed"></div>
    </div> -->
  </div>
</div>
    `
})
export class RedirectComponent implements OnInit {
    constructor(
        private _route: ActivatedRoute,
        private _madAuthService: MadAuthService
    ) {}

    async ngOnInit() {
        this.loadScript().then(async () => {
            const token = this._route.snapshot.queryParamMap.get('access_token') as string
            if (token) {
                const token = this._route.snapshot.queryParamMap.get('access_token') as string
                const user = await this._madAuthService.setAccessToken(token)
                const organisation = user.Account.Name;
                const region = user.Account.ServerLocation;
                const subdomain = slugify(organisation);
                const redirectURL = environment.outsetaRedirectURL;
                const url = `${redirectURL.protocol}://${subdomain}.${region ? region : 'eu'}.${redirectURL.url}?access_token=${token}`
                window.location.href = url;
            }
          })
    }

    loadScript() {
        // TODO: refactor
        return new Promise((resolve, reject) => {
            if (document.getElementById('outseta_js_options') != null) {
                document.getElementById('outseta_js_options').remove();
            }
            let script_option = document.createElement('script');
            script_option.id = 'outseta_js_options';
            script_option.text = `
            var o_options = {
                "domain": "interval.outseta.com",
                "monitorDom": true,
                "load": "auth,profile,support",
            }
            `

            if (document.getElementById('outseta_js') != null) {
                document.getElementById('outseta_js').remove();
            }
            let node = document.createElement('script');
            node.src = 'https://cdn.outseta.com/outseta.min.js';
            node.dataset.options = 'o_options';
            node.type = 'text/javascript';
            node.onload = function () {
                console.log('outseta loaded into the dom',);
            }
            // node.async = true;
            node.id = 'outseta_js';
            node.onload = () => {
                resolve({ script: name, loaded: true, status: 'Loaded' });
            };
            node.onerror = (error: any) => resolve({ script: name, loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script_option);
            document.getElementsByTagName('head')[0].appendChild(node);
        });
      }
}

function slugify(str: String) {
  return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
}
