import { CalculationMeta, CostRevenue } from "../../types"
import { IncomeValuationProcess } from "../../types/valuation-process"

export function updateMethodAndTiming(
  process: IncomeValuationProcess,
  {uuid, kind, data}: {
    uuid: string,
    kind: CostRevenue['kind'],
    data: {
      methodId: number,
      timingId: number
    }
  }
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: kind == 'lease' ? process.data.leases.map(item => _updateMethodAndTiming(item, uuid, data)) : process.data.leases,
      propertyRevenues: kind == 'property-revenue' ? process.data.propertyRevenues.map(item => _updateMethodAndTiming(item, uuid, data)) : process.data.propertyRevenues
    }
  }
}

function _updateMethodAndTiming<T extends {uid: string, calculationMeta: CalculationMeta}>(item: T, uid: string, data: {methodId: number, timingId: number}): T {
  if (item.uid === uid) {
    return {
      ...item,
      calculationMeta: {
        ...item.calculationMeta,
        methodId: data.methodId,
        timingId: data.timingId
      }
    }
  }
  return item
}