import { InputAmountType } from "src/app/core/v2/types"
import { CostRevenue } from "../../types"
import { 
  NonRecoverable, 
  Recoverable, 
  totalRecoverable as calculateTR ,
  totalNonRecoverable as calculateTNR,
  mapCost
} from "../../utils"
import { yieldPurchaseOnPerpetuityAnnually, yieldPurchaseOnPerpetuityQuarterly } from "./utils"

export function calculateFreeholdCapOnPerpetuity(
  effectiveRent: number,
  item: Recoverable & NonRecoverable & {expensesInputAmountType: InputAmountType, sizeValue: number},
  capitalRate: number,
  purchaserCost: number,
  timingId: number,
) {
  const totalRecoverable = mapCost(calculateTR(item) * 12, item.expensesInputAmountType, item.sizeValue)

  const totalNonRecoverable = mapCost(calculateTNR(item) * 12, item.expensesInputAmountType, item.sizeValue)

  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const yieldPurchaseOnPerpetuityAtCapitalRate = timingId === 1
    ? yieldPurchaseOnPerpetuityAnnually(capitalRate)
    : timingId === 2
      ? yieldPurchaseOnPerpetuityQuarterly(capitalRate)
      : undefined

  const capitalisedRent = yieldPurchaseOnPerpetuityAtCapitalRate * passingRent

  const totalPurchaserCost = (purchaserCost/100)  * capitalisedRent
  const value = capitalisedRent - totalPurchaserCost

  return {
    effectiveRent,
    passingRent,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: mapCost(item.recoverablePropertyManagement * 12, item.expensesInputAmountType, item.sizeValue),
      recoverableLeasingExpenses: mapCost(item.recoverableLeasingExpenses * 12, item.expensesInputAmountType, item.sizeValue), 
      recoverableUtilities: mapCost(item.recoverableUtilities * 12, item.expensesInputAmountType, item.sizeValue),
      recoverableMaintenance: mapCost(item.recoverableMaintenance * 12, item.expensesInputAmountType, item.sizeValue),
      recoverableInsurance: mapCost(item.recoverableInsurance * 12, item.expensesInputAmountType, item.sizeValue),
      recoverableJanitorial: mapCost(item.recoverableJanitorial * 12, item.expensesInputAmountType, item.sizeValue),
      recoverablePropertyTaxes: mapCost(item.recoverablePropertyTaxes * 12, item.expensesInputAmountType, item.sizeValue),
      recoverableBusiness: mapCost(item.recoverableBusiness * 12, item.expensesInputAmountType, item.sizeValue),
      recoverableOthers: mapCost(item.recoverableOthers * 12, item.expensesInputAmountType, item.sizeValue)
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: mapCost(item.nonRecoverablePropertyManagement * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableLeasingExpenses: mapCost(item.nonRecoverableLeasingExpenses * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableUtilities: mapCost(item.nonRecoverableUtilities * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableMaintenance: mapCost(item.nonRecoverableMaintenance * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableInsurance: mapCost(item.nonRecoverableInsurance * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableJanitorial: mapCost(item.nonRecoverableJanitorial * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverablePropertyTaxes: mapCost(item.nonRecoverablePropertyTaxes * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableBusiness: mapCost(item.nonRecoverableBusiness * 12, item.expensesInputAmountType, item.sizeValue),
      nonRecoverableOthers: mapCost(item.nonRecoverableOthers * 12, item.expensesInputAmountType, item.sizeValue)
    },
    yieldPurchaseOnPerpetuityAtCapitalRate,
    capitalisedRent: isNaN(capitalisedRent) ? undefined : capitalisedRent,
    totalPurchaserCost: isNaN(totalPurchaserCost) ? undefined : totalPurchaserCost,
    value: isNaN(value) ? undefined : value,
  }
}
