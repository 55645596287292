export interface RentReviewTypeListing {
  id: number
  name: string
}

export enum RentReviewTypes {
  FixedRentIncrease = 1,
  UpwardsOnlyMarketRent = 2,
  UpwardsDownwardsMarketRent = 3,
  CPI = 4,
  RPI = 5,
}

export interface RentReviewType {
  id: number
  name: string
  relation_cnt: number
  is_hidden: boolean
  is_system_entry: boolean,
  deleted_at?: string
  deletedBy?: string
  user_deleted?: string
  userDeletedBy?: string,
}