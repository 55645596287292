import { ValuationVpga10Matter } from "src/app/core/valuation/_models/vpga10-matter.model";
import { ValuationProcessState, vpga10Adapter } from "../_reducer";
import { Update } from "@ngrx/entity";
import { AssetClassModel } from "src/app/core/asset_class";
import { Status } from "../types";

export function addVpga10matter(
  state: ValuationProcessState['vpga10Matters'],
  data: ValuationVpga10Matter
): ValuationProcessState['vpga10Matters'] {
  const _new = Object.assign({}, data) as ValuationVpga10Matter;
  _new.front_id = state.lastCreatedId + 1;
  return vpga10Adapter.addOne(
    _new,
    { ...state, lastCreatedId: _new.front_id }
  )
}

export function editVpga10Matter(
  state: ValuationProcessState['vpga10Matters'],
  data: ValuationVpga10Matter
): ValuationProcessState['vpga10Matters'] {
  const _update: Update<ValuationVpga10Matter> = {
    id: data.front_id,
    changes: {
      ...data
    }
  };
  return vpga10Adapter.updateOne(_update, { ...state });
}

export function removeToeLocalVpga10Matter(
  state: ValuationProcessState['vpga10Matters'],
  data: ValuationVpga10Matter,
  tp: AssetClassModel,
  justification: string
): ValuationProcessState['vpga10Matters'] {
  const res = restore(tp, data);
  const _updated: Update<ValuationVpga10Matter> = {
    id: res.front_id,
    changes: {
      justification: justification,
      status: Status.Removed,
      input: res.input
    }
  };
  return vpga10Adapter.updateOne(_updated, { ...state });
}

export function removeInputVpga10Matter(
  state: ValuationProcessState['vpga10Matters'],
  id: number
): ValuationProcessState['vpga10Matters'] {
  return vpga10Adapter.removeOne(id, {...state})
}

export function restoreLocalVpga10Matter(
  state: ValuationProcessState['vpga10Matters'],
  data: ValuationVpga10Matter,
  tp: AssetClassModel,
): ValuationProcessState['vpga10Matters'] {
  const res = restore(tp, data);
  const _updated: Update<ValuationVpga10Matter> = {
    id: res.front_id,
    changes: {
      justification: null,
      status: Status.Valid,
      input: res.input
    }
  };
  return vpga10Adapter.updateOne(_updated, { ...state });
}

function restore(
  tp: AssetClassModel,
  item: ValuationVpga10Matter
): ValuationVpga10Matter {
  const _item = Object.assign({}, item) as ValuationVpga10Matter;
  const localVpga = tp.vpgas.find(vpga => vpga.id === item.local_vpga_id);
  if (localVpga) {
    _item.input = localVpga.vpga;
  }
  return _item;
}