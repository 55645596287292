import { calculatePresentValueAtPercentRate, calculateYearsPurchaseAtPercentRate } from "../../../../valuation/utils";
import { HeadlineRent, HeadlineToEffectiveTimeValueOfMoneySubMethod } from "../../types";
import { calculateHeadlineRent } from "./_headline-rent";
import { convertNaNToUndefined, roundResult } from "./_utils";

export function calculateTimeValueOfMoney(
  headlineRent: HeadlineRent,
  size: number,
  method: HeadlineToEffectiveTimeValueOfMoneySubMethod,
  capitalRate: number,
  targetRate: number
) {
  const firstRate = method === 'target-and-cap-rate' || method === 'target-rate'
    ? targetRate
    : capitalRate

  const secondRate = method === 'target-and-cap-rate' || method === 'cap-rate'
    ? capitalRate
    : targetRate
  const methodVersion = headlineRent.writeOffPeriodAfter !== null && headlineRent.writeOffPeriodAfter !== 0 && headlineRent.writeOffPeriodAfter < headlineRent.duration 
    ? 'write-off-is-lesser-than-lease'
    : 'write-off-is-equal-to-lease-or-unknown'

  const headlineRentValue = calculateHeadlineRent(
    headlineRent.rentInputAmount, 
    headlineRent.rentInputAmounType, 
    headlineRent.rentBasis ? headlineRent.rentBasis.id : null, 
    size
  )
  let rentReceivedFor = 0;
  if (methodVersion === 'write-off-is-lesser-than-lease') {
    rentReceivedFor = headlineRent.writeOffPeriodAfter - headlineRent.rentFreePeriod;
  } else {
    rentReceivedFor = headlineRent.duration - headlineRent.rentFreePeriod;
  }
  const yearsPurchaseOfRentReceived = calculateYearsPurchaseAtPercentRate(firstRate, rentReceivedFor);
  const presentValueOfRentFree = calculatePresentValueAtPercentRate(firstRate, headlineRent.rentFreePeriod);
  const capValOfHeadlineRent = headlineRentValue * yearsPurchaseOfRentReceived * presentValueOfRentFree;
  const capValOfInducements = capValOfHeadlineRent - headlineRent.capitalPayment;
  const leaseTermCapPeriod = rentReceivedFor + headlineRent.rentFreePeriod - headlineRent.fittingOutPeriod;
  const yearsPurchaseOfLeaseTerm = calculateYearsPurchaseAtPercentRate(secondRate, leaseTermCapPeriod);
  const presentValueOfFittingOut = calculatePresentValueAtPercentRate(secondRate, headlineRent.fittingOutPeriod);
  const deferredYearsPurchase = (yearsPurchaseOfLeaseTerm * presentValueOfFittingOut);
  const effectiveRent = capValOfInducements / deferredYearsPurchase;
  const result = {
    headlineRentValue,
    rentReceivedFor,
    yearsPurchaseOfRentReceived,
    presentValueOfRentFree,
    capValOfHeadlineRent,
    capValOfInducements,
    leaseTermCapPeriod,
    yearsPurchaseOfLeaseTerm,
    presentValueOfFittingOut,
    deferredYearsPurchase,
    effectiveRent
  }
  const rounded = roundResult(result);
  const converted = convertNaNToUndefined(rounded);
  return converted;
}