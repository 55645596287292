export function dateFormat(date: Date): string {
  if (date === null) return null
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  if (date) {
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  }

  return '';
}