export interface Recoverable {
  recoverablePropertyManagement: number | null
  recoverableLeasingExpenses: number | null
  recoverableUtilities: number | null
  recoverableMaintenance: number | null
  recoverableInsurance: number | null
  recoverableJanitorial: number | null
  recoverablePropertyTaxes: number | null
  recoverableBusiness: number | null
  recoverableOthers: number | null
}

export interface NonRecoverable {
  nonRecoverablePropertyManagement: number | null
  nonRecoverableLeasingExpenses: number | null
  nonRecoverableUtilities: number | null
  nonRecoverableMaintenance: number | null
  nonRecoverableInsurance: number | null
  nonRecoverableJanitorial: number | null
  nonRecoverablePropertyTaxes: number | null
  nonRecoverableBusiness: number | null
  nonRecoverableOthers: number | null
}

export function totalRecoverable(item: Recoverable): number {
  let total = 0
  if (item.recoverablePropertyManagement) {
    total += item.recoverablePropertyManagement
  }
  if (item.recoverableUtilities) {
    total += item.recoverableUtilities
  }
  if (item.recoverableInsurance) {
    total += item.recoverableInsurance
  }
  if (item.recoverablePropertyTaxes) {
    total += item.recoverablePropertyTaxes
  }
  if (item.recoverableLeasingExpenses) {
    total += item.recoverableLeasingExpenses
  }
  if (item.recoverableMaintenance) {
    total += item.recoverableMaintenance
  }
  if (item.recoverableJanitorial) {
    total += item.recoverableJanitorial
  }
  if (item.recoverableBusiness) {
    total += item.recoverableBusiness
  }
  if (item.recoverableOthers) {
    total += item.recoverableOthers
  }
  return total
} 

export function totalNonRecoverable(item: NonRecoverable): number {
  let total = 0
  if (item.nonRecoverablePropertyManagement) {
    total += item.nonRecoverablePropertyManagement
  }
  if (item.nonRecoverableUtilities) {
    total += item.nonRecoverableUtilities
  }
  if (item.nonRecoverableInsurance) {
    total += item.nonRecoverableInsurance
  }
  if (item.nonRecoverablePropertyTaxes) {
    total += item.nonRecoverablePropertyTaxes
  }
  if (item.nonRecoverableLeasingExpenses) {
    total += item.nonRecoverableLeasingExpenses
  }
  if (item.nonRecoverableMaintenance) {
    total += item.nonRecoverableMaintenance
  }
  if (item.nonRecoverableJanitorial) {
    total += item.nonRecoverableJanitorial
  }
  if (item.nonRecoverableBusiness) {
    total += item.nonRecoverableBusiness
  }
  if (item.nonRecoverableOthers) {
    total += item.nonRecoverableOthers
  }
  return total
}