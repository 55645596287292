import { Location } from "@angular/common";
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    of,
    Subject,
    Subscription,
} from "rxjs";
import {
    delay,
    filter,
    map,
    mergeMap,
    take,
    takeUntil,
    tap,
} from "rxjs/operators";
import {
    AgencyModel,
    AgencyService,
    AllAppendicesRequested,
    AllAppendixCategoriesRequested,
    AllCitiesRequested,
    AllClientsRequested,
    AllCountriesRequested,
} from "src/app/core/admin";
import {
    AllTpTaskRequested,
    AssetClassModel,
    ValuationModel,
    selectTpTasksByTpId,
} from "src/app/core/asset_class";
import { AllDraftStatementReportsRequested } from "src/app/core/asset_class/_actions/draft-statement-report.action";
import { AllValuationReportsRequested } from "src/app/core/asset_class/_actions/valuation-report.actions";
import { AssignmentModel, AssignmentsService } from "src/app/core/assignment";
import { currentUser, User } from "src/app/core/mad-auth/mad-auth.store";
import {
    AllAssetClassTypesRequested,
    AllBaseOfValuesRequested,
    AllChimneyStacksRequested,
    AllConservatoryPorchesRequested,
    AllCoordinateReferenceSystemsRequested,
    AllFloorTypesRequested,
    AllFoundationTypesRequested,
    AllGardensRequested,
    AllGradesRequested,
    AllHandoverStandardsRequested,
    AllMainWallsRequested,
    AllOtherJoinerysRequested,
    AllOutsideDoorsRequested,
    AllPlaningStatusesRequested,
    AllPremiseOfValuesRequested,
    AllPropertyTypesRequested,
    AllPurposeOfValuationsRequested,
    AllQosDegradationsRequested,
    AllRainwaterPipesRequested,
    AllRoofCoveringsRequested,
    AllRoofStructuresRequested,
    AllStandardMeasurementsRequested,
    AllStateRepairsRequested,
    AllTenureRequested,
    AllUnitAreaMeasurementsRequested,
    AllUnitMeasurementsRequested,
    AllValuerQualificationsRequested,
    AllWindowTypesRequested,
    selectAllValuerQualifications,
} from "src/app/core/linked-tables";
import { AllSubCategorysRequested } from "src/app/core/linked-tables/_actions/sub-category.actions";
import { AllSubTypeCategorysRequested } from "src/app/core/linked-tables/_actions/sub-type-category.actions";
import { AllTopPropertyTypesRequested } from "src/app/core/linked-tables/_actions/top-property-type.actions";
import { StandardMeasurementUtility } from "src/app/core/linked-tables/_services/standard-measurement-utility.service";
import { AppState } from "src/app/core/reducers";
import { ReportingRequested } from "src/app/core/reporting/_actions/reporting_process.actions";
import {
    ReportingProcessData,
    ReportingToeRelatedDocuments,
} from "src/app/core/reporting/_models/reporting_process.model";
import { selectReporting } from "src/app/core/reporting/_selectors/reporting_process.selectors";
import { ToeModel } from "src/app/core/toe";
import { SupportingValuerModel } from "src/app/core/toe/_models/supporting-valuer.model";
import { TypesUtilsService } from "src/app/core/_base/crud";
import { ReadonlyService } from "src/app/core/_base/crud/utils/readonly.service";
import { TabHeader } from "../../shared_components/tab-header/tab-header.component";
import { ConditionRatingV2Service } from "../services/condition-rating-v2.service";
import { DesignLayoutV2Component } from "./design-layout-v2/design-layout-v2.component";
import { ReportingSettingsV2Component } from "./reporting-settings/reporting-settings-v2.component";
import { Member } from "./types";
import { sortOriginalTasks, sortValuationTasks } from "src/app/core/tasks/task.utils";
import { moveLiquidationValuations } from "../_sub/tp-row/tp-row.component";

@Component({
    selector: "kt-reporting-process",
    templateUrl: "./reporting-process.component.html",
    styleUrls: ["./reporting-process.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [ConditionRatingV2Service, StandardMeasurementUtility],
})
export class ReportingProcessComponent implements OnInit, OnDestroy {
    @ViewChild(ReportingSettingsV2Component)
    settingsComponent: ReportingSettingsV2Component;
    // @ViewChild(DesignLayoutV2Component)
    // designLayoutV2Component: DesignLayoutV2Component;

    title$: Observable<string>;
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    valuationWithScenarios$: Observable<{
        valuation: any,
        valuation_index: number, 
        scenario: {
            sceneario_number: number
        }
    }[]>;
    valuations$: Observable<ValuationModel[]>;

    toe: ToeModel;
    tp: AssetClassModel;
    agency: AgencyModel;
    workers: SupportingValuerModel[] = [];
    leadValuer: SupportingValuerModel;
    members: SupportingValuerModel[] = [];
    assignment: AssignmentModel;
    reporting: ReportingProcessData;
    reportingLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    subscriptions: Subscription[] = [];
    currentUser: User;
    readonly: boolean = false;

    headerLabels: TabHeader[] = this._initialTabeHeaders();
    selectedTab: number = 0;

    toeMembers: Member[] = [];

    private _onDestroy: Subject<void> = new Subject();

    constructor(
        private activatedRoute: ActivatedRoute,
        private store$: Store<AppState>,
        private location: Location,
        private assignmentService: AssignmentsService,
        private agencyService: AgencyService,
        private readonlyService: ReadonlyService,
        private crService: ConditionRatingV2Service,
        private smUtilityService: StandardMeasurementUtility,
        private typesUtilsService: TypesUtilsService
    ) {}

    ngOnInit(): void {
        this.readonly = this.readonlyService.isReadOnly();
        this.store$.dispatch(new AllAssetClassTypesRequested());
        this.store$.dispatch(new AllPropertyTypesRequested());
        this.store$.dispatch(new AllTenureRequested());
        this.store$.dispatch(new AllGradesRequested());
        this.store$.dispatch(new AllCountriesRequested());
        this.store$.dispatch(new AllUnitMeasurementsRequested());
        this.store$.dispatch(new AllClientsRequested());
        this.store$.dispatch(new AllPurposeOfValuationsRequested());
        this.store$.dispatch(new AllBaseOfValuesRequested());
        this.store$.dispatch(new AllPremiseOfValuesRequested());
        this.store$.dispatch(new AllUnitAreaMeasurementsRequested());
        this.store$.dispatch(new AllAppendixCategoriesRequested());
        this.store$.dispatch(new AllAppendicesRequested());
        this.store$.dispatch(new AllTopPropertyTypesRequested());
        this.store$.dispatch(new AllSubTypeCategorysRequested());
        this.store$.dispatch(new AllSubCategorysRequested());
        this.store$.dispatch(new AllChimneyStacksRequested());
        this.store$.dispatch(new AllRoofStructuresRequested());
        this.store$.dispatch(new AllRoofCoveringsRequested());
        this.store$.dispatch(new AllRainwaterPipesRequested());
        this.store$.dispatch(new AllMainWallsRequested());
        this.store$.dispatch(new AllOutsideDoorsRequested());
        this.store$.dispatch(new AllConservatoryPorchesRequested());
        this.store$.dispatch(new AllOtherJoinerysRequested());
        this.store$.dispatch(new AllCoordinateReferenceSystemsRequested());
        this.store$.dispatch(new AllPlaningStatusesRequested());
        this.store$.dispatch(new AllQosDegradationsRequested());
        this.store$.dispatch(new AllGardensRequested());
        this.store$.dispatch(new AllFloorTypesRequested());
        this.store$.dispatch(new AllWindowTypesRequested());
        this.store$.dispatch(new AllStateRepairsRequested());
        this.store$.dispatch(new AllFoundationTypesRequested());
        this.store$.dispatch(new AllHandoverStandardsRequested());
        this.store$.dispatch(new AllStandardMeasurementsRequested());
        this.store$.dispatch(new AllValuerQualificationsRequested());

        combineLatest([
            this.store$.select(selectAllValuerQualifications),
            this.activatedRoute.data
        ]).subscribe(([qualifications, data]) => {
            const workers = data.workersData.data.filter(w => w.lead_valuer != 1);
            this.toeMembers = workers.map(w => {
                const q = qualifications.find(q => q.id == w.worker.qualification_id)
                return {
                    id: w.id,
                    fullName: `${w.worker.first_name} ${w.worker.last_name}`,
                    qualification: q ? q.name : null
                }
            })
        })

        this.activatedRoute.data
            .pipe(
                takeUntil(this._onDestroy),
                map((data) => {
                    // side effects
                    this.toe = Object.assign({}, data.toeData.data) as ToeModel;
                    this.tp = Object.assign(
                        {},
                        data.assetClassData.data
                    ) as AssetClassModel;
                    this.workers = data.workersData.data;
                    this.leadValuer = this.workers.find(
                        (w) => w.lead_valuer == 1
                    );
                    this.members = this.workers.filter(
                        (w) => w.lead_valuer != 1
                    );
                    this.store$.dispatch(
                        new AllTpTaskRequested({ toeID: this.toe.id })
                    );

                    this.crService.setComponentsAll(
                        this.smUtilityService,
                        this.tp.details,
                        this.tp
                    );
                    this.crService.setConditionRatingsFromRatingModels(
                        data.subData.ratings
                    );
                    this.crService.setSchemeId(
                        this.tp.details.aboutProperty.floor_numbering_scheme_id
                    );

                    return {
                        toe: this.toe,
                        tp: this.tp,
                    };
                }),
                tap(({ toe, tp }: { toe: ToeModel; tp: AssetClassModel }) => {
                    this.store$.dispatch(
                        new AllValuationReportsRequested({ tpID: tp.id })
                    );
                    this.title$ = of(
                        `${this.tp.name}'s Reporting process for the TOE "${this.toe.name}"`
                    );


                    this.valuationWithScenarios$ = this.store$.select(selectTpTasksByTpId(tp.id)).pipe(
                        map(tasks => tasks ? tasks : undefined),
                        filter(tasks => tasks != undefined),
                        map(tasks => tasks.filter(t => t.task_id == 2)),
                        map(tasks => moveLiquidationValuations(tasks)),
                        map(tasks => {
                            const originalTasks = sortOriginalTasks(tasks)
                            const sortedValuationTasks = sortValuationTasks(tasks, originalTasks)
                            return sortedValuationTasks
                        }),
                        tap(tasks => {
                            this.headerLabels = this._initialTabeHeaders();
                            tasks.forEach((t, i) => {
                                this.headerLabels.push({
                                    label: `${i + 2} - Valuation ${t.valuation_index + 1}` + (t.scenario ? ` (Scenario ${t.scenario.scenario_number})` : ''),
                                    disabled: of(false),
                                });
                            })
                            this.headerLabels.push({
                                label: `${tasks.length + 2} - Reporting`,
                                disabled: of(false),
                            });
                        })
                    )
                    this.valuations$ = this.valuationWithScenarios$.pipe(
                        map(tasks => tasks.map(t => t.valuation))
                    )
                    // this.valuations$ = of(
                    //     this.tp.valuations
                    //     // .filter(
                    //     //     (val) =>
                    //     //         val.methods_to_value_id == 2 ||
                    //     //         val.methods_to_value_id == 9
                    //     // )
                    // );
                    // this.tp.valuations
                        // .filter(
                        //     (val) =>
                        //         val.methods_to_value_id == 2 ||
                        //         val.methods_to_value_id == 9
                        // )
                        // .forEach((v, i) => {
                        //     this.headerLabels.push({
                        //         label: `${i + 2} - Valuation ${
                        //             this.tp.valuations.length == 1 ? "" : i + 1
                        //         }`,
                        //         disabled: of(false),
                        //     });
                        // });
                    // this.headerLabels.push({
                    //     label: `${
                    //         this.tp.valuations.length + 2
                    //     } - Design / Layout`,
                    //     disabled: of(false),
                    // });

                    if (tp.reporting_id !== null) {
                        this.store$.dispatch(
                            new ReportingRequested({ id: tp.reporting_id })
                        );
                    }
                }),
                mergeMap(({ toe, tp }) => {
                    return combineLatest([
                        this.assignmentService
                            .getById(toe.assignment_id)
                            .pipe(map((res) => res.data)),
                        this.store$.select(selectReporting(tp.reporting_id)),
                    ]);
                }),
                mergeMap(([assignment, reporting]) => {
                    this.reporting = reporting;
                    if (this.reporting == undefined) {
                        this.reporting =
                            this.createInitialReportingProcessModel(
                                this.tp.valuations.filter(
                                    (val) =>
                                        val.methods_to_value_id == 2 ||
                                        val.methods_to_value_id == 9 ||
                                        [7, 8, 9, 16, 18, 19, 20, 21, 23, 24, 25, 26, 27, 28, 29, 30, 33].includes(val.methods_to_value_id)
                                ),
                                this.toe,
                                this.leadValuer,
                                this.members
                            );
                    }
                    if (this.reporting.toe_related_docs == null) {
                        this.reporting = {
                            ...this.reporting,
                            toe_related_docs: this._toeRelatedDocs(
                                this.toe,
                                this.leadValuer,
                                this.members
                            ),
                        };
                    }
                    this.reportingLoaded.next(true);
                    this.assignment = assignment;
                    return this.agencyService
                        .getByAssignmentId(assignment.id)
                        .pipe(map((res) => res.data));
                })
            )
            .subscribe((agency) => {
                this.agency = agency;
                this.loading$.next(false);
            });

        const userSubscription = this.store$
            .pipe(take(1), select(currentUser))
            .subscribe((res) => {
                if (res) {
                    this.currentUser = res;
                }
            });
        this.subscriptions.push(userSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    back() {
        this.location.back();
    }

    onTabChange(index: number) {
        this.selectedTab = index;
    }

    onSubmit(complete: boolean) {
        // var designData = this.designLayoutV2Component.designData;
        this.settingsComponent.submitSetting(complete, {
            picture_location: 1,
            comparable_pictures: 1,
            report_cover: 1,
            property_pics: [],
            building_pics: [],
            videos: [],
            links: [],
        });
    }

    private createInitialReportingProcessModel(
        valuations: ValuationModel[],
        toe: ToeModel,
        leadValuer: SupportingValuerModel,
        members: SupportingValuerModel[]
    ): ReportingProcessData {
        const data: ReportingProcessData = {
            id: undefined,
            val_id: valuations[0].id,
            val_justification: null,
            valuation_scenarios: [],
            change_in_market_conditions: 0,
            change_in_market_conditions_explained: null,
            exchange_rates: [],
            comparable_pictures: 0,
            valuation_cover_picture: 0,
            tp_pictures: 0,
            tp_pictures_all_or_custom: 0,
            valuation_date: this.typesUtilsService.getDateStringFromDate(
                new Date(valuations[0].updated_at)
            ),
            status: 0,
            market_identification_n_input_levels: null,
            base_of_value_adopted: 1,
            base_of_value_adopted_desc: null,
            marketResearch: [],
            appendices: [],
            otherDocuments: [],
            tp_selected_pictures: [],
            use_round_up: false,
            has_vpga2: toe.purpose_of_valuation_id === 8,
            draft_is_shared_to_client: 0,
            has_material_change: 0,
            general_comments: {
                price_been_disclosed: "0",
                price_been_disclosed_descr: null,
                suitability_of_property: null,
                circumstances_could_affect_price: "0",
                demand_for_alternative_use: "0",
                occupational_demand_for_property: null,
                special_designation_to_property: "0",
                harmful_materials_been_noted: "0",
                environmental_issues_to_property: "0",
                volatility_in_market: null,
                assessment_of_current_marketability: null,
                any_other_matter_revealed: "0",
                impact_of_giving_incentive: "0",

                circumstances_could_affect_price_descr: null,
                demand_for_alternative_use_descr: null,
                special_designation_to_property_descr: null,
                harmful_materials_been_noted_descr: null,
                environmental_issues_to_property_descr: null,
                any_other_matter_revealed_descr: null,
                impact_of_giving_incentive_descr: null,
            },
            property_specific_disclosure: {
                type_of_property: 0,
                summary_of_occupational_leases: null,
                current_rental_income: null,
                market_view: null,
                assess_maintainability_of_income: null,
                any_potential_for_redevelopment: "0",
                comments_on_costs: null,
                comments_on_viability: null,
                implications_on_value: null,
                comments_on_time: null,
                any_potential_for_redevelopment_descr: null,
                market_value_impact: "",
                market_value_impact_descr: null,
            },
            summary_of_occupational_leases_files: [],
            current_rental_income_files: [],
            toe_id: null,
            ac_id: null,
            user_id: null,

            toe_related_docs: this._toeRelatedDocs(toe, leadValuer, members),
            property_pictures: [],
            building_pictures: [],
            videos: [],
            matterport_contents: [],
            has_team_signature: false,
            team_signature_ids: []
        };
        return data;
    }

    private _toeRelatedDocs(
        toe: ToeModel,
        leadValuer: SupportingValuerModel,
        members: SupportingValuerModel[]
    ): ReportingToeRelatedDocuments {
        return {
            agency_documents: toe.selected_agency_documents,
            lead_valuer: {
                certificates: leadValuer ? leadValuer.selected_certificate : [],
                documents: leadValuer ? leadValuer.selected_document : [],
            },
            members: members.map((m) => ({
                id: m.id,
                certificates: m.selected_certificate,
                documents: m.selected_document,
            })),
            otherDocs: toe.additional_files.map((f) => f.id),
        };
    }

    private _initialTabeHeaders(): TabHeader[] {
        return [
            { label: "1 - Target Property", disabled: of(false) },
        ]
    }
}
