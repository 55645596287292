<div ngbDropdown placement="bottom-right" *ngIf="user$ | async as _user" class="dropdown dropdown-fluid">
    <div ngbDropdownToggle class="topbar-item">
        <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{_user.first_name}}</span>
            <span class="symbol symbol-35 symbol-light-success">
				<img alt="Pic" [src]="_user.picture == '' ? './assets/media/users/default.jpg' : url + _user.picture"/>
			</span>
        </div>
    </div>

    <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
        <ng-container *ngIf="userDropdownStyle === 'light'">
            <div class="d-flex align-items-center p-8 rounded-top">
                <div class="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                    <span class="symbol-label font-size-h5 text-primary">{{_user.full_name|firstLetter}}</span>
                </div>

                <div class="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                    {{_user.full_name}}
                </div>
                <span class="label label-light-success label-lg font-weight-bold label-inline">3 messages</span>
            </div>
        </ng-container>

        <ng-container *ngIf="userDropdownStyle === 'dark'">
            <div class="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
                 style="background-image: url(./assets/media/misc/bg-1.jpg)">
                <div class="d-flex align-items-center mr-2">
                    <div class="symbol bg-white-o-15 mr-3">
                        <img alt="Pic" [src]="_user.picture == '' ? './assets/media/users/default.jpg' : url + _user.picture"/>
                    </div>

                    <div class="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                        {{_user.full_name}}
                    </div>
                </div>s
            </div>
        </ng-container>
        <div class="navi navi-spacer-x-0 pt-5">
            <ng-container *ngIf="!(user$|async).is_account_user">
                <a
                ngbDropdownItem
                href="javascript:;"
                [routerLink]="'/user-management/users/edit/' + (user$ | async).id"
                class="navi-item px-8">
                    <div class="navi-link">
                        <div class="navi-icon mr-2">
                            <i class="flaticon2-calendar-3 text-success"></i>
                        </div>
                        <div class="navi-text">
                            <div class="font-weight-bold">
                                My Profile
                            </div>
                            <div class="text-muted">
                                Account settings and more
                            </div>
                        </div>
                    </div>
                </a>

                <a ngbDropdownItem
                [routerLink]="'/my-task/pm/list-new'"
                [queryParams]="{'user_id': _user.id}"
                href="javascript:;" class="navi-item px-8">
                    <div class="navi-link">
                        <div class="navi-icon mr-2">
                            <i class="flaticon2-hourglass text-primary"></i>
                        </div>
                        <div class="navi-text">
                            <div class="font-weight-bold">
                                My Tasks
                            </div>
                            <div class="text-muted">
                                latest tasks and projects
                            </div>
                        </div>
                    </div>
                </a>
            </ng-container>

            <a *ngIf="hasPermissionToAccess$|async" ngbDropdownItem
                (click)="openProfile()"
               href="javascript:;" class="navi-item px-8">
                <div class="navi-link">
                    <div class="navi-icon mr-2">
                        <!-- <i class="flaticon2-hourglass text-primary"></i> -->
                        <div style="width: 26px; height: 26px">
                            <img width="20" height="20" src="assets/media/logos/outseta-logo.svg"/>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            Outseta
                        </div>
                        <div class="text-muted">
                            Outseta account settings and billing
                        </div>
                    </div>
                </div>
            </a>

            <a ngbDropdownItem
                (click)="openTicket()"
               href="javascript:;" class="navi-item px-8">
                <div class="navi-link">
                    <div class="navi-icon mr-2">
                        <!-- <i class="flaticon2-hourglass text-primary"></i> -->
                        <div style="width: 26px; height: 26px">
                            <img width="20" height="20" src="assets/media/logos/outseta-logo.svg"/>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            Support
                        </div>
                        <div class="text-muted">
                            Create a new ticket
                        </div>
                    </div>
                </div>
            </a>

            <a ngbDropdownItem
                (click)="openFaq()"
               href="javascript:;" class="navi-item px-8">
                <div class="navi-link">
                    <div class="navi-icon mr-2">
                        <!-- <i class="flaticon2-hourglass text-primary"></i> -->
                        <div style="width: 26px; height: 26px">
                            <img width="20" height="20" src="assets/media/logos/outseta-logo.svg"/>
                        </div>
                    </div>
                    <div class="navi-text">
                        <div class="font-weight-bold">
                            FAQ
                        </div>
                        <!-- <div class="text-muted">
                            Create a new ticket
                        </div> -->
                    </div>
                </div>
            </a>

            <div class="navi-separator mt-3"></div>
            <div class="navi-footer  px-8 py-5">
                <a href="javascript:;" (click)="logout()" class="btn btn-light-primary font-weight-bold">Sign Out</a>

            </div>
        </div>
    </div>
</div>
