import { createSelector } from "@ngrx/store";
import { selectFeature } from './_base'
import { ValuationVpga10Matter } from "src/app/core/valuation/_models/vpga10-matter.model";
import { each } from "lodash";

export const selectAllVpga10Matters = createSelector(
  selectFeature,
  _state => {
    const items: ValuationVpga10Matter[] = [];
    each(_state.vpga10Matters.entities, el => items.push(el));
    items.sort(function (a, b) {
      return a.source - b.source;
    })
    return items;
  }
)