import { Component, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';
import { MadLogin } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private _authService: MadAuthService,
    private _renderer2: Renderer2,
    private _store$: Store<AppState>,
    private _route: ActivatedRoute,
    private subDomainService: SubdomainService
  ) { 
    const prod = environment.production
    if (prod) {
      const isDemo = environment.isDemo;
      const redirectURL = environment.outsetaRedirectURL;
      if (isDemo) {
        const url = `${redirectURL.protocol}://demo.interval-soft.com/mad-auth/redirect`
        this._authService.loadLoginScript(this._renderer2, url)
      } else {
        const url = `${redirectURL.protocol}://app.interval-soft.com/mad-auth/redirect`
        this._authService.loadLoginScript(this._renderer2, url)
      }
    } else {
      const url = 'http://app.localhost:4200/mad-auth/redirect'
      this._authService.loadLoginScript(this._renderer2, url)
    }
  }

  ngOnInit(): void {
    const token = this._route.snapshot.queryParamMap.get('access_token') as string
    if (token) {
      this._store$.dispatch(new MadLogin({token}))
    }
  }
}
