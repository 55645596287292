import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { delay, map } from 'rxjs/operators'
import { environment } from "src/environments/environment";
import { HttpUtilsService } from "../../_base/crud";
import { PictureModel } from "../_models";
import { mapApartmentInternalAspectRemoteToDomain, mapVApartmentGroundRemoteToDomain } from "../_models/valuation-apartment.model";
import { mapVAssetClassSizeRemoteToDomain, mapVLocationRemoteToDomain, VAssetClassSizeRemote, VExternalAspectRemote, VGroundRemote, VInternalAspectRemote, VLandAreaComponentRemote } from "../_models/valuation-asset-class-common.models";
import { mapVTPHouseRemoteToDomain } from "../_models/valuation-house.model";
import { mapVTPLandRemoteToDomain } from "../_models/valuation-land.model";
import { mapVTPOfficeRemoteToDomain } from "../_models/valuation-office.model";
import { mapVTPParkingRemoteToDomain } from "../_models/valuation-parking.model";
import { ValuationProcessDataModel } from "../_models/valuation-process-data.model";
import { ValuationProcessApartmentModel, ValuationProcessAssetClassGeneralInfoModel, ValuationProcessBuildingInfo, ValuationProcessHouseModel, ValuationProcessLandModel, ValuationProcessOfficeModel, ValuationProcessParkingModel, ValuationProcessRetailBuildingModel, ValuationProcessRetailShopModel, ValuationProcessTargetPropertyInfo, ValuationProcessTargetPropertyModel, ValuationProcessWareouseModel } from "../_models/valuation-process-target-property.model";
import { mapVTPRetailBuildingRemoteToDomain } from "../_models/valuation-retail-building.model";
import { mapVTPRetailShopRemoteToDomain } from "../_models/valuation-retail-shop.model";
import { mapVTPWarehouseRemoteToDomain } from "../_models/valuation-warehouse.model";
import { VParcelIdentificationRemote } from '../_models/valuation-land-parcel.model'
import ValuationUtils from "../_utils/map";
import { computeAdjustedConsiderations, computeAdjustedGrossUnitRates, computeTotalAdjustments, computeTPValue, computeWeightedAvgRates, computeWeightedTotal, getBuildingInfo, getSourceDates, getConsiderationTypeRelatedInfo, getConsiderationTypeRelatedInfo2 } from "../_selectors/valuation.selectors";
import { mapFullRemoteToDomain } from "./valuation-comparable.service";
import { MadAuthService } from "../../mad-auth/mad-auth.service";
import { SubdomainService } from "../../_base/subdomain.service";
import { ValidatedValuationModel } from "../_models/validated-valuation.model";
import { getSummary } from "../../v2/state/valuation-process/core/income";



export type ValuationProcessTargetPropertyRemote = {
    id: number
    name: string,

    propertyTypeId: number,
    propertyTypeName: string,
    propertySubTypeId: number,
    propertySubTypeName: string,
    subTypeCategoryId: number,
    subTypeCategoryName: string,
    subCategoryId: number,
    subCategoryName: string,

    locationData?: {
        countryName: string,
        cityName: string,
        zipCode: string,
        address: string,
        latitude: string,
        longitude: string,
        timezoneOffset: string | null
    },
    locationGrade: string,

    instructedToMeasure: boolean,
    measurementStandardId: number,
    measurementStandardName: string,
    size: {
        value: number,
        unitAreaMeasurementAcronym: string
        unitAreaMeasurement: any,
        unitMeasurementName: string
    }

    accommodation: any,
    floorNumberingSchemeId: number,
    bedroom: number,
    selectedExternalArea: any,

    inspectionTypeId: number,
    inspectionType: string,
    inspectionDate: Date,
    draftDate: Date,
    finalDate: Date,

    propertyGrade: string,
    landParcel: {
        lp_path: Array<{ lat: string, lng: string }>,
        planningStatusName: string,
        coordinateRefSystem: string,
        totalBuildings: number,
        building_exists: number,
        buildings: any[],
        landAreas: VLandAreaComponentRemote[],
        land_cover_type: string,
        qos_degradation_name: string,
        comparative_land_use_name: string,
        sol_use_classification_name: string,
        other_characteristics: string,
        land_use_approval_implementation: string,
    }

    pictures: PictureModel[],
    buildingInfo: ValuationProcessBuildingInfo

    measurementStandard: any,

    anyLimitationsOrRestrictions: boolean,
    limitationDesc: string,
    locationSurrounding: string,

    aboutProperty: {
        generalDescription: string,
        stateOfRepairName: string,
        handoverStandardName: string,
        floorLocation: string,
        energyEfficiencyGradeName: string,
        above_floors: number,
        below_floors: number,
        construction_desc: string,
        property_grade_name: string,
        completion_year: string,
        apprx_year_extended: string,
        foundation_type_name: string,
        number_of_units: number,
        parking_type: string,
        automation: string
    },
    internalAspect: VInternalAspectRemote,
    externalAspect: VExternalAspectRemote,
    ground: VGroundRemote
    sizes: VAssetClassSizeRemote[],
    parcel_identification: VParcelIdentificationRemote,
    service_and_infra: {
        id: number,
        road: boolean;
        status_n_quality: string,
        access_points: string,
        length_of_road: string,
        ownership: string,

        central_heating: boolean,
        heatings: string[],
        other_heating: string,

        all_of_it: boolean,
        services: string[],
        other_service: string
    }

    country_currency: string


    oldBuildingInfo: any,
}

// type ValuationProcessDataRemote = Omit<ValuationProcessDataModel, 'targetProperty'> & { targetProperty: ValuationProcessTargetPropertyRemote }

@Injectable()
export class ValuationProcessService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/valuation-process`
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) { }

    fetchData(targetPropertyId: number, valuationId: number): Observable<{ 
        data: ValuationProcessDataModel, 
        comparables: any[], 
        adjustmentTab: any,
    }> {
        const headers = this.authService.getAuthHeaders()
        const url = `${this.API_URL}/${targetPropertyId}/${valuationId}`
        return this.http.get<{ data: any }>(url, { headers })
            .pipe(
                map(response => response.data),
                map(response => {
                    return {
                        data: {
                            valuation: response.valuation,
                            targetProperty: this.mapTPRemoteToTpModel(response.targetProperty),
                            additional: response.additional,
                            assumptionDepartures: response.assumptionDepartures,
                            notes: response.notes,
                            landMarks: response.landMarks.data,
                            vpga10_matters: response.vpga10_matters,
                            sers: response.sers,
                            propertySubTypeIds: response.property_sub_type_ids,
                            liquidationValues: response.liquidationValues
                                ? {
                                    import_other_valuations: response.liquidationValues.import_other_valuations,
                                    imported_valuation_id: response.liquidationValues.imported_valuation_id,
                                    disposal_period: response.liquidationValues.disposal_period,
                                    disposal_period_type: response.liquidationValues.disposal_period_type,
                                    has_discount: response.liquidationValues.has_discount,
                                    discount: response.liquidationValues.discount
                                }
                                : null
                        },
                        comparables: response.comparables,
                        adjustmentTab: response.adjustment_data,
                    }
                })
            )
    }

    storeData(targetPropertyId: number, valuationid: number, body: any) {
        const headers = this.authService.getAuthHeaders()
        const url = `${this.API_URL}/store/${targetPropertyId}/${valuationid}`
        return this.http.post(url, body, { headers })
            .pipe(map(response => response))
    }

    private incomeMethod(valuationId: number, data: any) {
        const comparables = data.comparables.map(item => mapFullRemoteToDomain(item)).filter(item => item != null)
        return {
            kind: 'income',
            comparables,
            id: valuationId,
            method_name: data.method_name,
            summary: getSummary(data)
        }
    }

    private marketMethod(valuationId: number, data: any) {
            const defaultSize = data.adjustment_data.sizecriterions.find(c => c.active)
            const totalAdjustments = computeTotalAdjustments(data.adjustment_data.adjustments)
            const adjustedConsiderations = computeAdjustedConsiderations(
                totalAdjustments,
                data.adjustment_data.considerationCrit,
                data.ref_nums
            )
            const adjustedGrossUnitRates = computeAdjustedGrossUnitRates(
                adjustedConsiderations,
                defaultSize,
                data.ref_nums
            )
            const weightedAvgRates = computeWeightedAvgRates(data.adjustment_data.valuation.weightings, adjustedGrossUnitRates)
            const totalWeighted = computeWeightedTotal(weightedAvgRates)
            const tpValue = computeTPValue(totalWeighted, defaultSize ? defaultSize.values['tp'] : null);
            const totalTPValue = tpValue == null ? null : (tpValue + data.adjustment_data.valuation.capitalAllowance)
            const totalTPWeighted = totalTPValue == null
                ? null
                : defaultSize == null || defaultSize.values['tp'] == null || defaultSize.values['tp'] == 0
                    ? null
                    : totalTPValue / defaultSize.values['tp']

            const considerationCriterion = data.adjustment_data.considerationCrit;
            const tenureRelatedInfo = getConsiderationTypeRelatedInfo(considerationCriterion)
            const tenureRelatedInfo2 = getConsiderationTypeRelatedInfo2(considerationCriterion)

            const adjustedConsiderationRowHeader = `Adjusted Consideration${tenureRelatedInfo} (${considerationCriterion.countryCurrency})`

            const adjustedGrossUnitRateRowHeader = `Adjusted Gross Unit Rate${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${data.unit_measurement_name})`


            const weightedAvgRowHeader = `Weighted Average Gross Unit Rate${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${data.unit_measurement_name})`

            const tpRowHeader = `Target Property${tenureRelatedInfo2} (${considerationCriterion.countryCurrency})`

            const capitalAllowanceRowHeader = `Capital Allowance (${considerationCriterion.countryCurrency})`

            const totalTPRowHeader = `Total Target Property Value (${considerationCriterion.countryCurrency})`
            const totalTPWeightedHeader = `Total Target Property Value (${considerationCriterion.countryCurrency}/${data.unit_measurement_name})`

            const sizeRowHeader = `Size (${defaultSize.name}) (${data.unit_measurement_name})`
            const sourceDates = getSourceDates(considerationCriterion)
            const totalAdjustmentsRow = `Total Adjustment (%) (${data.adjustment_data.adjustments.length} adjustment${data.adjustment_data.adjustments.length != 1 ? 's' : ''})`
            const tpInUnitRowHeader = `Target property${tenureRelatedInfo} (${considerationCriterion.countryCurrency}/${data.unit_measurement_name})`
            const comparables = data.comparables.map(item => mapFullRemoteToDomain(item)).filter(item => item != null)
            const buildingInfo = getBuildingInfo(comparables)

            const premiseOfValueName = data.valuation?.premise_of_value?.name
            const liquidationDiscountRowHeader = `${premiseOfValueName} Discount (${data.liquidation_values ? data.liquidation_values.discount : '0'}%) (${considerationCriterion.countryCurrency})`
            const liquidationValueRowHeader = `Liquidation Value (${considerationCriterion.countryCurrency})`
            const liquidationHasDiscount = data.liquidation_values
                ? data.liquidation_values.has_discount
                : false
            const liquidationDiscount = data.liquidation_values
                ? data.liquidation_values.discount
                : 0
            const totalDiscount = liquidationDiscount * totalTPValue / 100
            const liquidationValue = totalTPValue - totalDiscount

            return {
                kind: 'market',
                id: valuationId,
                method_name: data.method_name,
                comparables,
                refNums: data.ref_nums,
                totalAdjustments,
                adjustedConsiderations,
                adjustedGrossUnitRates,
                weightings: data.adjustment_data.valuation.weightings,
                totalWeight: data.adjustment_data.valuation.totalWeighting,
                is100: data.adjustment_data.valuation.totalWeighting == 100,
                weightedAvgRates,
                totalWeighted,
                tpValue,
                totalTPValue,
                totalTPWeighted,
                adjustedConsiderationRowHeader,
                adjustedGrossUnitRateRowHeader,

                justification: data.adjustment_data.valuation.justification,

                weightedAvgRowHeader,
                tpRowHeader,
                capitalAllowanceRowHeader,
                capitalAllowance: data.adjustment_data.valuation.capitalAllowance,
                capitalAllowanceJustification: data.adjustment_data.valuation.capitalAllowanceJustification,
                totalTPRowHeader,
                totalTPWeightedHeader,

                sizeRowHeader,
                sizes: defaultSize.values,

                considerationRowHeader: considerationCriterion.categoryName,
                considerations: considerationCriterion.values,

                sourceDates,
                totalAdjustmentsRow,
                tpInUnitRowHeader,
                buildingInfo,
                liquidationValueSection: {
                    hasDiscount: liquidationHasDiscount,
                    premiseOfValueName,
                    liquidationDiscountRowHeader,
                    liquidationValueRowHeader,
                    totalDiscount,
                    value: liquidationValue
                }
            }
    }

    summaryData(targetPropertyId: number, valuationId: number) {
        const headers = this.authService.getAuthHeaders()
        const url = `${this.API_URL}/${targetPropertyId}/${valuationId}/summary`
        return this.http.get(url, { headers }).pipe(map((data: any) => {
            if (data.method === 'income') {
                return this.incomeMethod(valuationId, data)
            }
            return this.marketMethod(valuationId, data)
        }))
    }

    getTargetProperty(tpId: number): Observable<ValuationProcessTargetPropertyModel> {
        const url = `${this.API_URL}/tp/${tpId}`;
        return this.http.get<{data: any}>(url).pipe(
            map(response => response.data),
            map(response => {
                return this.mapTPRemoteToTpModel(response.targetProperty)
            })
        )
    }

    private mapTPRemoteToTpModel(tpRemote: ValuationProcessTargetPropertyRemote): ValuationProcessTargetPropertyModel {
        const generalInfo = this.mapTPRemoteToGeneralInfo(tpRemote)
        return {
            id: tpRemote.id,
            name: tpRemote.name,

            generalInfo: generalInfo,
            info: this.mapFullInfoRemoteToDomain(tpRemote, generalInfo),

            instructedToMeasure: tpRemote.instructedToMeasure,
            inspectionDate: tpRemote.inspectionDate,
            inspectionType: tpRemote.inspectionType,
            draftDate: tpRemote.draftDate,
            finalDate: tpRemote.finalDate,
            timezone: tpRemote.locationData.timezoneOffset,

            pictures: tpRemote.pictures,
            buildingInfo: tpRemote.buildingInfo,
            countryCurrency: tpRemote.country_currency
        }
    }

    private mapFullInfoRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessAssetClassGeneralInfoModel): ValuationProcessTargetPropertyInfo {
        switch (generalInfo.propertySubType) {
            case 'Apartment': {
                return {
                    refNum: 'tp',
                    propertySubType: generalInfo.propertySubType,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    building: {
                        name: tpRemote.buildingInfo.name,
                        completionYear: tpRemote.buildingInfo.completionYear,
                        buildingType: tpRemote.buildingInfo.buildingType == 'N/A' || tpRemote.buildingInfo.buildingType == null ? 'Unknown' : tpRemote.buildingInfo.buildingType,
                        buildingTypeComment: tpRemote.buildingInfo.buildingTypeComment,
                        buildingGrade: tpRemote.buildingInfo.buildingGrade == 'N/A' || tpRemote.buildingInfo.buildingGrade == null ? 'Unknown' : tpRemote.buildingInfo.buildingGrade,
                        energyEfficiencyGrade: tpRemote.buildingInfo.energyEfficiencyGrade,
                        developer: tpRemote.buildingInfo.developer,
                        anchorTenant: tpRemote.buildingInfo.anchorTenant,
                        foundationType: tpRemote.buildingInfo.foundationType,
                        buildingDescription: tpRemote.buildingInfo.buildingDescription,
                        pictures: tpRemote.buildingInfo.pictures
                    },
                    locationData: {
                        latitude: generalInfo.locationData.latitude,
                        longitude: generalInfo.locationData.longitude,
                        country: generalInfo.locationData.countryName,
                        city: generalInfo.locationData.cityName,
                        zipCode: generalInfo.locationData.zipCode,
                        address: generalInfo.locationData.address,
                        timezoneOffset: generalInfo.locationData.timezoneOffset,
                        locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
                        locationSurrounding: tpRemote.locationSurrounding
                    },
                    locationSurrounding: tpRemote.locationSurrounding,
                    inspectionInfo: {
                        typeOfInspection: tpRemote.inspectionTypeId,
                        inspectionDate: new Date(tpRemote.inspectionDate),
                        anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
                        limitationDescription: tpRemote.limitationDesc
                    },
                    property: {
                        subCategory: tpRemote.subCategoryName == 'N/A' ? 'Unknown' : tpRemote.subCategoryName,
                        subTypeCategory: tpRemote.subTypeCategoryName == 'N/A' ? 'Unknown' : tpRemote.subTypeCategoryName,
                        generalDescription: tpRemote.aboutProperty.generalDescription,
                        stateOfRepair: tpRemote.aboutProperty.stateOfRepairName,
                        handoverStandard: tpRemote.aboutProperty.handoverStandardName,
                        floors: tpRemote.aboutProperty.floorLocation,
                        energyEfficiencyGrade: tpRemote.aboutProperty.energyEfficiencyGradeName,
                    },
                    pictures: tpRemote.pictures,
                    oldBuildingInfo: tpRemote.oldBuildingInfo,
                    internalAspect: mapApartmentInternalAspectRemoteToDomain(tpRemote.internalAspect),
                    ground: mapVApartmentGroundRemoteToDomain(tpRemote.ground),
                    sizes: mapVAssetClassSizeRemoteToDomain(tpRemote.sizes)
                }
            }
            case 'House': {
                return mapVTPHouseRemoteToDomain(tpRemote, generalInfo)
            }
            case 'Office': {
                return mapVTPOfficeRemoteToDomain(tpRemote, generalInfo)
            }
            case 'Retail Shop': {
                return mapVTPRetailShopRemoteToDomain(tpRemote, generalInfo)
            }
            case 'Retail Building': {
                return mapVTPRetailBuildingRemoteToDomain(tpRemote, generalInfo)
            }
            case 'Warehouse': {
                return mapVTPWarehouseRemoteToDomain(tpRemote, generalInfo)
            }
            case 'Parking': {
                return mapVTPParkingRemoteToDomain(tpRemote, generalInfo)
            }
            case 'Land': {
                return mapVTPLandRemoteToDomain(tpRemote, generalInfo)
            }
            default: {
                return null
            }
        }
    }

    private mapTPRemoteToGeneralInfo(tpRemote: ValuationProcessTargetPropertyRemote): ValuationProcessAssetClassGeneralInfoModel {
        switch (tpRemote.propertySubTypeId) {
            case 1: {
                const data: ValuationProcessApartmentModel = {
                    propertyType: 'Residential',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'Apartment',
                    propertySubTypeId: 1,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    bedroom: tpRemote.bedroom,
                    selectedExternalArea: tpRemote.selectedExternalArea,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 3: {
                const data: ValuationProcessOfficeModel = {
                    propertyType: 'Commercial',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'Office',
                    propertySubTypeId: 3,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    selectedExternalArea: tpRemote.selectedExternalArea,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 5: {
                const data: ValuationProcessRetailShopModel = {
                    propertyType: 'Commercial',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'Retail Shop',
                    propertySubTypeId: 5,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    selectedExternalArea: tpRemote.selectedExternalArea,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 11: {
                const data: ValuationProcessRetailBuildingModel = {
                    propertyType: 'Commercial',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'Retail Building',
                    propertySubTypeId: 11,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    propertyGrade: tpRemote.propertyGrade,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 17: {
                const data: ValuationProcessHouseModel = {
                    propertyType: 'Residential',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'House',
                    propertySubTypeId: 17,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    propertyGrade: tpRemote.propertyGrade,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 2: {
                const data: ValuationProcessParkingModel = {
                    propertyType: 'Commercial',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'Parking',
                    propertySubTypeId: 2,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 7: {
                const data: ValuationProcessWareouseModel = {
                    propertyType: 'Industrial',
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubType: 'Warehouse',
                    propertySubTypeId: 7,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    subTypeCategoryId: tpRemote.subTypeCategoryId,
                    subTypeCategoryName: tpRemote.subTypeCategoryName,
                    subCategoryId: tpRemote.subCategoryId,
                    subCategoryName: tpRemote.subCategoryName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: Number(tpRemote.locationData?.latitude),
                        longitude: Number(tpRemote.locationData?.longitude),
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    measurementStandardId: tpRemote.measurementStandardId,
                    measurementStandard: tpRemote.measurementStandardName,
                    measurementStandardModel: tpRemote.measurementStandard,
                    size: tpRemote.size.value,
                    unitAreaMeasurementAcronym: tpRemote.size.unitAreaMeasurementAcronym,
                    unitAreaMeasurementModel: tpRemote.size.unitAreaMeasurement,
                    floorNumberingSchemeId: tpRemote.floorNumberingSchemeId,
                    accommodation: tpRemote.accommodation,
                    propertyGrade: tpRemote.propertyGrade,
                    unitMeasurementName: tpRemote.size.unitMeasurementName
                }
                return data;
            }
            case 13: {
                const center = ValuationUtils.getCenter(tpRemote.landParcel.lp_path)
                const totalLandParcel = tpRemote.landParcel.landAreas.length
                let totalLandArea = 0
                let unitMeasurement = ''
                let unitMeasurementName = ''
                if (totalLandParcel > 0) {
                    unitMeasurement = tpRemote.landParcel.landAreas[0].unit_area_measurement_acronym
                    unitMeasurementName = tpRemote.landParcel.landAreas[0].unit_area_measurement_name
                    totalLandArea = tpRemote.landParcel.landAreas.reduce((acc, v) => acc + v.surface, 0)
                }
                const data: ValuationProcessLandModel = {
                    propertySubType: 'Land',
                    propertySubTypeId: 13,
                    propertyTypeName: tpRemote.propertyTypeName,
                    propertySubTypeName: tpRemote.propertySubTypeName,
                    locationData: {
                        countryName: tpRemote.locationData?.countryName,
                        cityName: tpRemote.locationData?.cityName,
                        zipCode: tpRemote.locationData?.zipCode,
                        address: tpRemote.locationData?.address,
                        latitude: center.lat,
                        longitude: center.lng,
                        timezoneOffset: tpRemote.locationData?.timezoneOffset
                    },
                    locationGrade: tpRemote.locationGrade,
                    planningStatusName: tpRemote.landParcel.planningStatusName,
                    coordinateReferenceSystemName: tpRemote.landParcel.coordinateRefSystem,
                    numOfBuilding: tpRemote.landParcel.building_exists == 0 ? tpRemote.landParcel.totalBuildings : 'Unknown',
                    totalLandArea,
                    totalLandParcel,
                    unitMeasurement,
                    unitMeasurementName,
                }
                return data;
            }
            default:
                return null
        }
    }
}