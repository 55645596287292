import { createAction, props } from "@ngrx/store";
import { Lease, PropertyRevenue } from "../types";

export const addLease = createAction(
  '[Valuation Process Page - V2 / Lease Detail Tab] Add Lease',
  props<{
    lease: Lease
  }>()
)

export const editLease = createAction(
  '[Valuation Process Page - V2 / Lease Detail Tab] Edit Lease',
  props<{
    lease: Lease
  }>()
)

export const deleteLease = createAction(
  '[Valuation Process Page - V2 / Lease Detail Tab] Delete Lease',
  props<{
    lease: Lease
  }>()
)

export const addRevenue = createAction(
  '[Valuation Process Page - V2 / Lease Detail Tab] Add Revenue',
  props<{
    revenue: PropertyRevenue
  }>()
)

export const editRevenue = createAction(
  '[Valuation Process Page - V2 / Lease Detail Tab] Edit Revenue',
  props<{
    revenue: PropertyRevenue
  }>()
)

export const deleteRevenue = createAction(
  '[Valuation Process Page - V2 / Lease Detail Tab] Delete Revenue',
  props<{
    revenue: PropertyRevenue
  }>()
)