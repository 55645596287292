export const environment = {
    production: true,
    isDemo: true,
    isMockEnabled: true, // You have to switch this, when your real back-end is done
    authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
    baseApiUrl: 'https://demo.interval-soft.com:444/',
    baseMediaUrl: 'https://demo.interval-soft.com:444/',
    wsHost: 'demo.interval-soft.com',
    wsPort: '6001',
    agMapKey: 'AIzaSyDpObiL4cyKZ0Eprnatnow77n2fj4Ly1c4',
    baseApiReportUrl: 'https://demo.interval-soft.com:445/',
    accessToken: '1e43ca86279ff05c7e4621f8d998dca4',
    myAccAPI: 'https://api.vimeo.com/users/159034026',
    createLinkAPI: 'https://api.vimeo.com/me/videos',
    zero: 0,
    outsetaLoginUrl: 'http://demo.interval-soft.com/mad-auth/post-login',
    mapCenter: {
        lat: 48.84872,
        lng: 2.27176
    },
    outsetaRedirectURL: {
        protocol: 'https',
        url: 'demo.interval-soft.com/mad-auth/post-login',
    },
};
