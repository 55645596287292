import { createFeatureSelector, createSelector } from "@ngrx/store";
import { featureKey, ValuationProcessState } from "../_reducer";
import { leases, leasesAndPropertyRevenues } from "./_income";
import { EffectiveRentUtil } from "../core/headline-rent";

export const selectFeature = createFeatureSelector<ValuationProcessState>(featureKey)

export const valuationProcessLoading = createSelector(
  selectFeature,
  state => state.isLoading
)
export const valuationProcess = createSelector(
  selectFeature,
  state => state.valuationProcess
)

export const targetProperty = createSelector(
  selectFeature,
  state => state.targetProperty
)

export const valuation = createSelector(
  selectFeature,
  state => state.valuation
)

export const additional = createSelector(
  selectFeature,
  state => state.additional
)