import { CostRevenue } from "../../types";
import * as _ from 'lodash'
import { EffectiveRentUtil } from "../headline-rent";
import { durationCalculation } from "src/app/core/v2/valuation";
import { mapCost, totalNonRecoverable, totalRecoverable } from "../../utils";
import { calculationResult } from "../../_selectors";

function groupCostRevenuesByPropertySubType(
  items: CostRevenue[]
) {
  return items.reduce(
    (acc, item) => {
      if (!item.propertySubType) return acc
      const propertySubType = item.propertySubType.name
      if (acc[propertySubType]) {
        return {
          ...acc,
          [propertySubType]: [...acc[propertySubType], item]
        }
      }
      return {
        ...acc,
        [propertySubType]: [item]
      }
    },
    {} as Record<string, CostRevenue[]>
  )
}

export function financialInformation(
  items: CostRevenue[],
  sizes: {
    totalLeasedArea: number,
    tpSize: number
  },
  valuationDate: Date
) {
  const grouped = groupCostRevenuesByPropertySubType(items)
  const columns = Object.entries(grouped).map(([key, values]) => {
    const mapped = values.map(item => {
      const size = item.kind === 'lease'
        ? item.leasedArea
        : item.size === 'total_lease'
          ? sizes.totalLeasedArea
          : item.size === 'tp'
            ? sizes.tpSize
            : item.sizeInput
      const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
      const result = calculationResult(
        item,
        effectiveRent,
        sizes,
        valuationDate
      )
      const leaseNetInitialYield = result.passingRent / result.value
      const leaseGrossInitialYield = effectiveRent / result.value
      return {
        leaseNetInitialYield,
        leaseGrossInitialYield
      }
    })

    const totalLeaseNetInitialYield = mapped.reduce((acc, item) => acc + item.leaseNetInitialYield, 0)
    const totalLeaseGrossInitialYield = mapped.reduce((acc, item) => acc + item.leaseGrossInitialYield, 0)

    return {
      propertySubType: key,
      avgNetInitialYield: (totalLeaseNetInitialYield / values.length) * 100,
      avgGrossInitialYield: (totalLeaseGrossInitialYield / values.length) * 100,
      avgRevisionaryYield: undefined
    }
  })

  return columns
}


export function leasesAndRentInformation(
  items: CostRevenue[],
  sizes: {
    totalLeasedArea: number,
    tpSize: number
  },
  valuationDate: Date
) {
  const grouped = groupCostRevenuesByPropertySubType(items)
  const columns = Object.entries(grouped).map(([key, values]) => {
    const mapped = values.map(item => {
      const size = item.kind === 'lease'
        ? item.leasedArea
        : item.size === 'total_lease'
          ? sizes.totalLeasedArea
          : item.size === 'tp'
            ? sizes.tpSize
            : item.sizeInput
      const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
      const result = calculationResult(
        item,
        effectiveRent,
        sizes,
        valuationDate
      )
      const sizeValue = item.kind === 'lease'
        ? item.leasedArea
        : item.size === 'total_lease'
          ? sizes.totalLeasedArea
          : item.size === 'tp'
            ? sizes.tpSize
            : item.sizeInput
      const recoverable = mapCost(totalRecoverable(item), item.expensesInputAmountType, sizeValue)
      const nonRecoverable = mapCost(totalNonRecoverable(item), item.expensesInputAmountType, sizeValue)
      const basisMultiplier = item.rentBasis
        ? item.rentBasis.id === 1
          ? 12
          : item.rentBasis.id === 2
            ? 4
            : 1
        : 1

      return {
        effectiveRent,
        passingRent: result.passingRent,
        leaseDuration: durationCalculation(item.duration, item.durationType),
        rentFreePeriod: durationCalculation(item.rentFreePeriod, item.durationType),
        fittingOutPeriod: durationCalculation(item.fittingOutPeriod, item.durationType),
        rentReviewCycle: durationCalculation(item.rentReviewCycle, item.durationType),
        totalRecoverable: recoverable * basisMultiplier,
        totalNonRecoverable: nonRecoverable * basisMultiplier
      }
    })
    const totalEffectiveRent = mapped.reduce((acc, item) => acc + item.effectiveRent, 0)
    const totalPassingRent = mapped.reduce((acc, item) => acc + item.passingRent, 0)
    const totalLeaseDuration = mapped.reduce((acc, item) => acc + item.leaseDuration, 0)
    const totalRentFreePeriod = mapped.reduce((acc, item) => acc + item.rentFreePeriod, 0)
    const totalFittingOutPeriod = mapped.reduce((acc, item) => acc + item.fittingOutPeriod, 0)
    const totalRentReviewCycle = mapped.reduce((acc, item) => acc + item.rentReviewCycle, 0)
    const totalAllRecoverable = mapped.reduce((acc, item) => acc + item.totalRecoverable, 0)
    const totalAllNonRecoverable = mapped.reduce((acc, item) => acc + item.totalNonRecoverable, 0)

    return {
      propertySubType: key,
      avgGrossRentPerYear: totalEffectiveRent / values.length,
      avgNetRentPerYear: totalPassingRent / values.length,
      avgLeaseDuration: totalLeaseDuration / values.length,
      avgRentFreePeriod: totalRentFreePeriod / values.length,
      avgFittingOutPeriod: totalFittingOutPeriod / values.length,
      avgRentReviewCycle: totalRentReviewCycle / values.length,
      avgRecoverable: totalAllRecoverable / values.length,
      avgNonRecoverable: totalAllNonRecoverable / values.length
    }
  })

  return columns
}