import { createSelector } from "@ngrx/store";
import { selectFeature, targetProperty } from "./_base";
import * as _ from "lodash";
import { CostRevenue } from "../types";
import { EffectiveRentUtil } from "../core/headline-rent";
import { calculateFreeholdCapOnPerpetuity } from "../core/income";
import { ConvertedCostRevenueUtils } from "../types/converted-cost-revenue";
import { calculateFreeholdCapOnShortTerm } from "../core/income/freehold-cap-on-short-term";
import { calculateTermAndReversionHadrcoreLayer } from "../core/income/term-and-reversion-hardcore-layer";
import { calculateTermAndReversionHardcoreFroth } from "../core/income/term-and-reversion-hardcore-froth";

export const leases = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    return state.valuationProcess.data.leases
  }
)

export const propertyRevenues = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    return state.valuationProcess.data.propertyRevenues
  }
)

export const valuationDate = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return null
    if (state.valuationProcess.kind === 'market') return null
    return state.valuationProcess.data.valuationDate
  }
)

export const leasesAndPropertyRevenues = createSelector(
  leases,
  propertyRevenues,
  (leases, propertyRevenues) => {
    const costRevenues: Array<CostRevenue & {label: string}>  = [
      ...leases.map((item, index) => ({...item, label: `L #${index + 1}`})),
      ...propertyRevenues.map((item, index) => ({...item, label: `O #${index + 1}`}))
    ]
    return costRevenues
  }
)

const sizes = createSelector(
  leases,
  targetProperty,
  (leases, targetProperty) => {
    if (targetProperty === null) return {totalLeasedArea: 0, tpSize: 0}
    const totalLeasedArea = leases.reduce((acc, lease) => acc + lease.leasedArea, 0)
    let tpSize = 0
    if (targetProperty.info.sizes.length > 0) {
      tpSize = targetProperty.info.sizes[0].size
    }
    return {
      totalLeasedArea,
      tpSize,
    }
  }
)
export const valuationValid = createSelector(
  leasesAndPropertyRevenues,
  sizes,
  valuationDate,
  (items, sizes, valuationDate) => {
    const results = items.map(item => {
      const size = item.kind == 'lease'
        ? item.leasedArea
        : item.size === 'total_lease'
          ? sizes.totalLeasedArea
          : item.size === 'tp'
            ? sizes.tpSize
            : item.sizeInput
      const effectiveRent = EffectiveRentUtil.getValue(item, sizes)
      const result = calculationResult(item, effectiveRent, sizes, valuationDate)
      return result 
    })
    if (results.some(item => item === undefined)) return undefined
    const totalValue = results.reduce((acc, item) => {
      return acc + item.value
    }, 0)
    return totalValue !== undefined && totalValue >= 0
  }
)

export function calculationResult(
  costRevenue: CostRevenue, 
  effectiveRent: number, 
  sizes: {totalLeasedArea: number, tpSize: number},
  valuationDate: Date
) {
  if (costRevenue.calculationMeta.methodId == 16) {
    const size = costRevenue.kind === 'lease'
      ? costRevenue.leasedArea
      : costRevenue.size === 'total_lease'
        ? sizes.totalLeasedArea
        : costRevenue.size === 'tp'
          ? sizes.tpSize
          : costRevenue.sizeInput
    return calculateFreeholdCapOnPerpetuity(
      effectiveRent,
      {...costRevenue, sizeValue: size},
      costRevenue.calculationMeta.capitalisationRate,
      costRevenue.calculationMeta.purchasersCost,
      costRevenue.calculationMeta.timingId
    )
  }

  if (costRevenue.calculationMeta.methodId == 18) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)

    return calculateFreeholdCapOnShortTerm(
      effectiveRent,
      convertedCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: false,
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        estimatedVoidPeriod: costRevenue.calculationMeta.estimatedVoidPeriod,
        growthRate: costRevenue.calculationMeta.growthRatePerYear,
        purchaserCost: costRevenue.calculationMeta.purchasersCost
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 19) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)

    return calculateTermAndReversionHadrcoreLayer(
      effectiveRent,
      convertedCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: false,
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        estimatedVoidPeriod: costRevenue.calculationMeta.estimatedVoidPeriod,
        growthRate: costRevenue.calculationMeta.growthRatePerYear,
        purchaserCost: costRevenue.calculationMeta.purchasersCost,
        marketRent: costRevenue.calculationMeta.marketRent,
        reversionaryYield: costRevenue.calculationMeta.reversionaryYield
      }
    )
  }
  if (costRevenue.calculationMeta.methodId == 20) {
    const convertedCostRevenue = costRevenue.kind == 'lease' 
      ? ConvertedCostRevenueUtils.fromLease(costRevenue)
      : ConvertedCostRevenueUtils.fromRevenue(costRevenue, sizes.totalLeasedArea, sizes.tpSize)

    return calculateTermAndReversionHardcoreFroth(
      effectiveRent,
      convertedCostRevenue,
      valuationDate,
      {
        activeBreakOffOption: false,
        timingId: costRevenue.calculationMeta.timingId,
        allRiskYield: costRevenue.calculationMeta.allRiskYield,
        purchaserCost: costRevenue.calculationMeta.purchasersCost,
        marketRent: costRevenue.calculationMeta.marketRent,
        reversionaryYield: costRevenue.calculationMeta.reversionaryYield
      }
    )
  }
  return undefined
}