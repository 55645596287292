import { roundTo4Decimals } from "../../../../valuation/utils"

export function roundResult<T extends object>(val: T): T {
  const res = Object.assign({}, val) as T
  Object.entries(val).forEach(([field, value]) => {
    res[field] = roundTo4Decimals(value)
  })
  return res
}

export function convertNaNToUndefined<T extends object>(val: T): T {
  const res = Object.assign({}, val) as T
  Object.entries(val).forEach(([field, value]) => {
    res[field] = Number.isNaN(value) ? undefined : value;
  });
  return res;
}