// Angular
import {Component, Input, OnInit} from '@angular/core';
// RxJS
import {Observable} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
// State
import {AppState} from '../../../../../core/reducers';
import { currentUser, hasPermission, MadLogOut, User } from 'src/app/core/mad-auth/mad-auth.store'
import {environment} from '../../../../../../environments/environment';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';

@Component({
    selector: 'kt-user-profile',
    templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
    // Public properties
    user$: Observable<User>;
    url = environment.baseApiUrl;

    @Input() userDropdownStyle = 'dark';
    @Input() avatar = true;
    @Input() greeting = true;
    @Input() badge: boolean;
    @Input() icon: boolean;

    hasPermissionToAccess$ = this.store.select(hasPermission(['profile:edit', 'profile:view']))

    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     */
    constructor(private store: Store<AppState>, private _authService: MadAuthService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit(): void {
        this.user$ = this.store.pipe(select(currentUser));
    }

    /**
     * Log out
     */
    logout() {
        // this.store.dispatch(new NotificationClear());
        this.store.dispatch(new MadLogOut());
    }

    openProfile() {
        this._authService.openProfile();
    }

    openTicket() {
        this._authService.openTicket();
    }
    openFaq() {
        window.open("https://interval.outseta.com/support/kb/categories", "_blank");
    }
}
