import { InputAmountType } from "../../../../types";

export function calculateHeadlineRent(
  amount: number,
  amountType: InputAmountType,
  rentBasisId: number,
  size: number
): number {
  let _amount = 0;
  if (amountType === 'total') {
    _amount = amount
  } else if (amountType === 'per_units') {
    const sizeUnit = size ? size : 0
    _amount = amount * sizeUnit
  }


  if (rentBasisId === 1) {
    return _amount * 12
  } else if (rentBasisId === 2) {
    return _amount * 4
  } else {
    return _amount
  }
}