import { createSelector } from "@ngrx/store";
import { selectFeature } from "./_base";
import { ValuationNoteModel } from "src/app/core/valuation/_models/valuation-note.model";
import { each } from "lodash";

export const selectAllNotes = createSelector(
  selectFeature,
  _state => {
    const items: ValuationNoteModel[] = [];
    each(_state.notes.entities, el => items.push(el));
    return items;
  }
) 