import { Lease, PropertyRevenue } from "../../types";
import { IncomeValuationProcess } from "../../types/valuation-process";

export function addLease(
  process: IncomeValuationProcess,
  item: Lease
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: _addItem(process.data.leases, item) 
    }
  }
}

export function editLease(
  process: IncomeValuationProcess,
  item: Lease
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: _editItem(process.data.leases, item) 
    }
  }
}

export function removeLease(
  process: IncomeValuationProcess,
  item: Lease
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: process.data.leases.filter(_item => _item.uid !== item.uid)
    }
  }
}

export function addRevenue(
  process: IncomeValuationProcess,
  item: PropertyRevenue
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      propertyRevenues: _addItem(process.data.propertyRevenues, item)
    }
  }
}

export function editRevenue(
  process: IncomeValuationProcess,
  item: PropertyRevenue
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      propertyRevenues: _editItem(process.data.propertyRevenues, item)
    }
  }
}

export function removeRevenue(
  process: IncomeValuationProcess,
  item: PropertyRevenue
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      propertyRevenues: process.data.propertyRevenues.filter(_item => _item.uid !== item.uid)
    }
  }
}

function _addItem<T>(items: T[], item: T): T[] {
  return [...items, item]
}

type ItemWithId = {
  uid: string
}

function _editItem<T extends ItemWithId>(items: T[], item: T): T[] {
  return items.map(_item => {
    if (_item.uid === item.uid) {
      return item
    }
    return _item
  })
}