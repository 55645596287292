import { ToesDataSource } from "src/app/core/toe";
import { CalculationMeta, CostRevenue } from "../../types";
import { IncomeValuationProcess } from "../../types/valuation-process";

export function updateModelAssumptions(
  process: IncomeValuationProcess,
  {uuid, kind, data}: {
    uuid: string,
    kind: CostRevenue['kind'],
    data: Partial<CalculationMeta>
  }
): IncomeValuationProcess {
  return {
    ...process,
    data: {
      ...process.data,
      leases: kind === 'lease' ? process.data.leases.map(item => _updateModelAssumptions(item, uuid, data)) : process.data.leases,
      propertyRevenues: kind == 'property-revenue' ? process.data.propertyRevenues.map(item => _updateModelAssumptions(item, uuid, data)) : process.data.propertyRevenues
    }
  }
}

function _updateModelAssumptions<T extends {uid: string, calculationMeta: CalculationMeta}>(item: T, uuid: string, data: Partial<CalculationMeta>): T {
  if (data.methodId == 16) {
    return updateFreeholdCapRateOnPerpetuityModelAssumption(item, uuid, data)
  }
  if (data.methodId == 18) {
    return updateFreeholdCapRateOnShortTermModelAssumption(item, uuid, data)
  }
  if (data.methodId == 19) {
    return updateTermAndReversionHardcoreLayer(item, uuid, data)
  }
  if (data.methodId == 20) {
    return updateTermAndReversionHardcoreFroth(item, uuid, data)
  }
  return item
}

function updateFreeholdCapRateOnShortTermModelAssumption<
  T extends {uid: string, calculationMeta: CalculationMeta}
>(item: T, uuid: string, data: Partial<CalculationMeta>): T {
  if (item.uid === uuid) {
    return {
      ...item,
      calculationMeta: {
        ...item.calculationMeta,
        growthRatePerYear: data.growthRatePerYear,
        activeBreakOption: data.activeBreakOption,
        allRiskYield: data.allRiskYield,
        allRiskYieldJustification: data.allRiskYieldJustification,
        estimatedVoidPeriod: data.estimatedVoidPeriod,
        estimatedVoidPeriodJustification: data.estimatedVoidPeriodJustification,
        purchasersCost: data.purchasersCost
      }
    }
  }
  return item
}

function updateFreeholdCapRateOnPerpetuityModelAssumption<
  T extends {uid: string, calculationMeta: CalculationMeta}
>(item: T, uuid: string, data: Partial<CalculationMeta>): T {
  if (item.uid === uuid) {
    return {
      ...item,
      calculationMeta: {
        ...item.calculationMeta,
        capitalisationRate: data.capitalisationRate,
        capitalisationRateJustification: data.capitalisationRateJustification,
        estimatedVoidPeriod: data.estimatedVoidPeriod,
        estimatedVoidPeriodJustification: data.estimatedVoidPeriodJustification,
        purchasersCost: data.purchasersCost
      }
    }
  }
  return item
}

function updateTermAndReversionHardcoreLayer<
  T extends {uid: string, calculationMeta: CalculationMeta}
>(item: T, uuid: string, data: Partial<CalculationMeta>): T {
  if (item.uid === uuid) {
    return {
      ...item,
      calculationMeta: {
        ...item.calculationMeta,
        marketRent: data.marketRent,
        activeBreakOption: data.activeBreakOption,
        growthRatePerYear: data.growthRatePerYear,
        allRiskYield: data.allRiskYield,
        allRiskYieldJustification: data.allRiskYieldJustification,
        reversionaryYield: data.reversionaryYield,
        reversionaryYieldJustification: data.reversionaryYieldJustification,
        estimatedVoidPeriod: data.estimatedVoidPeriod,
        estimatedVoidPeriodJustification: data.estimatedVoidPeriodJustification,
        purchasersCost: data.purchasersCost
      }
    }
  }
  return item
}

function updateTermAndReversionHardcoreFroth<
  T extends {uid: string, calculationMeta: CalculationMeta}
>(item: T, uuid: string, data: Partial<CalculationMeta>): T {
  if (item.uid === uuid) {
    return {
      ...item,
      calculationMeta: {
        ...item.calculationMeta,
        marketRent: data.marketRent,
        growthRatePerYear: data.growthRatePerYear,
        activeBreakOption: data.activeBreakOption,
        allRiskYield: data.allRiskYield,
        allRiskYieldJustification: data.allRiskYieldJustification,
        reversionaryYield: data.reversionaryYield,
        reversionaryYieldJustification: data.reversionaryYieldJustification,
        purchasersCost: data.purchasersCost,
        estimatedVoidPeriod: data.estimatedVoidPeriod,
        estimatedVoidPeriodJustification: data.estimatedVoidPeriodJustification,
      }
    }
  }
  return item
}