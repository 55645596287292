import { Update } from "@ngrx/entity";
import { defaultAssumptionAdapter, departureAdapter, specialAssumptionAdapter, ValuationProcessState } from "../_reducer";
import { Status, Type, ValuationAssumptionDeparture } from "../types";
import { AssetClassModel } from "src/app/core/asset_class";

export function addAssumptionOrDeparture(
  state: ValuationProcessState['assumptionDeparture'],
  data: ValuationAssumptionDeparture
): ValuationProcessState['assumptionDeparture'] {
  const id = lastCreatedId(state, data.type)
  const _new = Object.assign({}, data) as ValuationAssumptionDeparture;
  _new.front_id = id + 1;
  switch (data.type) {
    case Type.DefaultAssumption: {
      return {
        ...state, default: defaultAssumptionAdapter.addOne(
          _new,
          { ...state.default, lastCreatedId: _new.front_id }
        )
      }
    }
    case Type.SpecialAssumption: {
      return {
        ...state, special: specialAssumptionAdapter.addOne(
          _new,
          { ...state.special, lastCreatedId: _new.front_id }
        )
      }
    }
    case Type.Departure: {
      return {
        ...state, departure: departureAdapter.addOne(
          _new,
          { ...state.departure, lastCreatedId: _new.front_id }
        )
      }
    }
  }
}

function lastCreatedId(state: ValuationProcessState['assumptionDeparture'], type: Type) {
  switch (type) {
    case Type.DefaultAssumption: {
      return state.default.lastCreatedId
    }
    case Type.SpecialAssumption: {
      return state.special.lastCreatedId
    }
    case Type.Departure: {
      return state.departure.lastCreatedId
    }
  }
}

export function editAssumptionOrDeparture(
  state: ValuationProcessState['assumptionDeparture'],
  data: ValuationAssumptionDeparture
): ValuationProcessState['assumptionDeparture'] {
  const _update: Update<ValuationAssumptionDeparture> = {
    id: data.front_id,
    changes: {
      ...data
    }
  }
  switch (data.type) {
    case Type.DefaultAssumption: {
      return { ...state, default: defaultAssumptionAdapter.updateOne(_update, { ...state.default }) }
    }
    case Type.SpecialAssumption: {
      return { ...state, special: specialAssumptionAdapter.updateOne(_update, { ...state.special }) }
    }
    case Type.Departure: {
      return { ...state, departure: departureAdapter.updateOne(_update, { ...state.departure }) }
    }
  }
}

export function removeToeAssumptionOrDeparture(
  state: ValuationProcessState['assumptionDeparture'],
  data: ValuationAssumptionDeparture,
  tp: AssetClassModel,
  justification: string
): ValuationProcessState['assumptionDeparture'] {
  const restored = restore(tp, data);
  const _update: Update<ValuationAssumptionDeparture> = {
    id: restored.front_id,
    changes: {
      justification: justification,
      status: Status.Removed,
      input: restored.input
    }
  }
  switch (data.type) {
    case Type.DefaultAssumption: {
      return { ...state, default: defaultAssumptionAdapter.updateOne(_update, { ...state.default }) }
    }
    case Type.SpecialAssumption: {
      return { ...state, special: specialAssumptionAdapter.updateOne(_update, { ...state.special }) }
    }
    case Type.Departure: {
      return { ...state, departure: departureAdapter.updateOne(_update, { ...state.departure }) }
    }
  }
}

export function removeInputAssumptionOrDeparture(
  state: ValuationProcessState['assumptionDeparture'],
  data: ValuationAssumptionDeparture,
): ValuationProcessState['assumptionDeparture'] {
  switch (data.type) {
    case Type.DefaultAssumption: {
      return { ...state, default: defaultAssumptionAdapter.removeOne(data.front_id, { ...state.default }) }
    }
    case Type.SpecialAssumption: {
      return { ...state, special: specialAssumptionAdapter.removeOne(data.front_id, { ...state.special }) }
    }
    case Type.Departure: {
      return { ...state, departure: departureAdapter.removeOne(data.front_id, { ...state.departure }) }
    }
  }
}

export function restoreAssumptionOrDeparture(
  state: ValuationProcessState['assumptionDeparture'],
  data: ValuationAssumptionDeparture,
  tp: AssetClassModel
): ValuationProcessState['assumptionDeparture'] {
  const restored = restore(tp, data);
  const _update: Update<ValuationAssumptionDeparture> = {
    id: restored.front_id,
    changes: {
      justification: null,
      status: Status.Valid,
      input: restored.input
    }
  }
  switch (data.type) {
    case Type.DefaultAssumption: {
      return { ...state, default: defaultAssumptionAdapter.updateOne(_update, { ...state.default }) }
    }
    case Type.SpecialAssumption: {
      return { ...state, special: specialAssumptionAdapter.updateOne(_update, { ...state.special }) }
    }
    case Type.Departure: {
      return { ...state, departure: departureAdapter.updateOne(_update, { ...state.departure }) }
    }
  }
}

function restore(
  tp: AssetClassModel,
  item: ValuationAssumptionDeparture
): ValuationAssumptionDeparture {
  const _item = Object.assign({}, item) as ValuationAssumptionDeparture;
  switch (_item.type) {
    case Type.DefaultAssumption: {
      tp.selected_default_assumptions.forEach((a: any) => {
        if (a.id === item.ref_id) {
          _item.input = a.default_assumption ? a.default_assumption.name : a.default_assumption_name;
        }
      });
      break;
    }
    case Type.SpecialAssumption: {
      tp.selected_special_assumptions.forEach((a: any) => {
        if (a.id === item.ref_id) {
          _item.input = a.special_assumption ? a.special_assumption.name : a.special_assumption_name;
        }
      })
      break;
    }
    case Type.Departure: {
      tp.selected_departures.forEach((d: any) => {
        if (d.id === item.ref_id) {
          _item.input = d.departure
            ? d.valuation_standard_name + '|' + d.departure.name
            : d.valuation_standard_name + '|' + d.departure_name;
        }
      })
      break;
    }
  }
  return _item;
}