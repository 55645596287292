import { createSelector } from "@ngrx/store";
import { selectFeature } from "./_base";
import * as _ from "lodash";

export const comparables = createSelector(
  selectFeature,
  state => state.comparables
)

export const selectedPropertySubTypeIds = createSelector(
  selectFeature,
  state => state.selectedPropertySubTypes
)

export const selectedComparables = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    if (state.valuationProcess.kind === 'income') {
      return _.flatten(Object.values(state.valuationProcess.data.comparables))
    }
    return []
  }
)

export const fullComparablesLoading = createSelector(
  selectFeature,
  state => state.fullComparablesLoading
)

export const fullComparables = (refNums: string[]) => createSelector(
  selectFeature,
  state => state.fullComparables.filter(item => refNums.includes(item.refNum))
)

export const selectedConsiderations = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    return state.valuationProcess.data.selectedConsiderations
  }
)

export const comparableHeadlineToEffectives = createSelector(
  selectFeature,
  state => {
    if (state.valuationProcess.kind === 'unknown') return []
    if (state.valuationProcess.kind === 'market') return []
    return state.valuationProcess.data.comparableHeadlineToEffectives
  }
)