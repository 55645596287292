import { InputAmountType } from "../../../types";
import { durationCalculation } from "../../../valuation";
import { Lease, PropertyRevenue } from "../types";
import { HeadlineRent } from "../types/headline-rent";

export function mapLeaseToHeadlineRent(lease: Lease): HeadlineRent {
  return {
    currency: lease.currency,
    size: lease.leasedArea,

    startDate: lease.startDate,
    duration: durationCalculation(lease.duration, lease.durationType),
    rentFreePeriod: durationCalculation(lease.rentFreePeriod, lease.durationType),
    fittingOutPeriod: durationCalculation(lease.fittingOutPeriod, lease.durationType),
    writeOffPeriodAfter: durationCalculation(lease.writeOffPeriodAfter, lease.durationType),
    breakOptionAfter: durationCalculation(lease.breakOptionAfter, lease.durationType),
    unexpiredLeaseTerms: lease.unexpiredLeaseTerms,

    capitalPayment: lease.capitalPayment,

    rentInputAmount: lease.rentInputAmount,
    rentInputAmounType: lease.rentInputAmountType,
    rentBasis: lease.rentBasis,
    rentReviewCycle: durationCalculation(lease.rentReviewCycle, lease.durationType),
    rentReviewType: lease.rentReviewType,

    recoverableBusiness: mapCost(lease.recoverableBusiness, lease.expensesInputAmountType, lease.leasedArea),
    recoverableInsurance: mapCost(lease.recoverableInsurance, lease.expensesInputAmountType, lease.leasedArea),
    recoverableJanitorial: mapCost(lease.recoverableJanitorial, lease.expensesInputAmountType, lease.leasedArea),
    recoverableLeasingExpenses: mapCost(lease.recoverableLeasingExpenses, lease.expensesInputAmountType, lease.leasedArea),
    recoverableMaintenance: mapCost(lease.recoverableMaintenance, lease.expensesInputAmountType, lease.leasedArea),
    recoverableOthers: mapCost(lease.recoverableOthers, lease.expensesInputAmountType, lease.leasedArea),
    recoverablePropertyManagement: mapCost(lease.recoverablePropertyManagement, lease.expensesInputAmountType, lease.leasedArea),
    recoverablePropertyTaxes: mapCost(lease.recoverablePropertyTaxes, lease.expensesInputAmountType, lease.leasedArea),
    recoverableUtilities: mapCost(lease.recoverableUtilities, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableBusiness: mapCost(lease.nonRecoverableBusiness, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableInsurance: mapCost(lease.nonRecoverableInsurance, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableJanitorial: mapCost(lease.nonRecoverableJanitorial, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableLeasingExpenses: mapCost(lease.nonRecoverableLeasingExpenses, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableMaintenance: mapCost(lease.nonRecoverableMaintenance, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableOthers: mapCost(lease.nonRecoverableOthers, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverablePropertyManagement: mapCost(lease.nonRecoverablePropertyManagement, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverablePropertyTaxes: mapCost(lease.nonRecoverablePropertyTaxes, lease.expensesInputAmountType, lease.leasedArea),
    nonRecoverableUtilities: mapCost(lease.nonRecoverableUtilities, lease.expensesInputAmountType, lease.leasedArea),
    expensesInputAmountType: lease.expensesInputAmountType
  }
}

export function mapPropertyRevenueToHeadlineRent(lease: PropertyRevenue, tpSize: number, totalLeasedArea: number): HeadlineRent {
  const size = lease.size === 'total_lease' 
    ? totalLeasedArea 
    : lease.size === 'tp' 
      ? tpSize
      : lease.sizeInput;
  return {
    currency: lease.currency,
    size: size,

    startDate: lease.startDate,
    duration: durationCalculation(lease.duration, lease.durationType),
    rentFreePeriod: durationCalculation(lease.rentFreePeriod, lease.durationType),
    fittingOutPeriod: durationCalculation(lease.fittingOutPeriod, lease.durationType),
    writeOffPeriodAfter: durationCalculation(lease.writeOffPeriodAfter, lease.durationType),
    breakOptionAfter: durationCalculation(lease.breakOptionAfter, lease.durationType),
    unexpiredLeaseTerms: lease.unexpiredLeaseTerms,

    capitalPayment: lease.capitalPayment,

    rentInputAmount: lease.rentInputAmount,
    rentInputAmounType: lease.rentInputAmountType,
    rentBasis: lease.rentBasis,
    rentReviewCycle: durationCalculation(lease.rentReviewCycle, lease.durationType),
    rentReviewType: lease.rentReviewType,

    recoverableBusiness: mapCost(lease.recoverableBusiness, lease.expensesInputAmountType, size),
    recoverableInsurance: mapCost(lease.recoverableInsurance, lease.expensesInputAmountType, size),
    recoverableJanitorial: mapCost(lease.recoverableJanitorial, lease.expensesInputAmountType, size),
    recoverableLeasingExpenses: mapCost(lease.recoverableLeasingExpenses, lease.expensesInputAmountType, size),
    recoverableMaintenance: mapCost(lease.recoverableMaintenance, lease.expensesInputAmountType, size),
    recoverableOthers: mapCost(lease.recoverableOthers, lease.expensesInputAmountType, size),
    recoverablePropertyManagement: mapCost(lease.recoverablePropertyManagement, lease.expensesInputAmountType, size),
    recoverablePropertyTaxes: mapCost(lease.recoverablePropertyTaxes, lease.expensesInputAmountType, size),
    recoverableUtilities: mapCost(lease.recoverableUtilities, lease.expensesInputAmountType, size),
    nonRecoverableBusiness: mapCost(lease.nonRecoverableBusiness, lease.expensesInputAmountType, size),
    nonRecoverableInsurance: mapCost(lease.nonRecoverableInsurance, lease.expensesInputAmountType, size),
    nonRecoverableJanitorial: mapCost(lease.nonRecoverableJanitorial, lease.expensesInputAmountType, size),
    nonRecoverableLeasingExpenses: mapCost(lease.nonRecoverableLeasingExpenses, lease.expensesInputAmountType, size),
    nonRecoverableMaintenance: mapCost(lease.nonRecoverableMaintenance, lease.expensesInputAmountType, size),
    nonRecoverableOthers: mapCost(lease.nonRecoverableOthers, lease.expensesInputAmountType, size),
    nonRecoverablePropertyManagement: mapCost(lease.nonRecoverablePropertyManagement, lease.expensesInputAmountType, size),
    nonRecoverablePropertyTaxes: mapCost(lease.nonRecoverablePropertyTaxes, lease.expensesInputAmountType, size),
    nonRecoverableUtilities: mapCost(lease.nonRecoverableUtilities, lease.expensesInputAmountType, size),
    expensesInputAmountType: lease.expensesInputAmountType
  }
}

export function mapCost(value: number, type: InputAmountType, size: number) {
  if (value === null) return null;
  if (type === 'per_units') return value * size;
  return value;
}