import { createAction, props } from "@ngrx/store";
import { AssetClassModel } from "src/app/core/asset_class";
import { SRERAssumptionType, ValuationSRERModel } from "src/app/core/valuation/_models/valuation-srer.model";

export const updateSrer = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Update SRER',
  props<{
    data: ValuationSRERModel
  }>()
)

export const restoreSrer = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Restore SRER',
  props<{
    data: ValuationSRERModel,
    sType: SRERAssumptionType,
    tp: AssetClassModel
  }>()
)

export const removeSrer = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Remove SRER',
  props<{
    data: ValuationSRERModel,
    sType: SRERAssumptionType,
    tp: AssetClassModel,
    justification: string
  }>()
)