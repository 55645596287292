import { createSelector } from '@ngrx/store'
import { selectFeature } from './_base'
import { Source, Status, Type, ValuationAssumptionDeparture } from '../types';
import { each } from 'lodash';
export const selectAllAssumptionDeparture = createSelector(
  selectFeature,
  _state => {
    const state = _state.assumptionDeparture
    const items: ValuationAssumptionDeparture[] = [];

    const defaultAssumptions: ValuationAssumptionDeparture[] = [];
    each(state.default.entities, el => defaultAssumptions.push(el));
    defaultAssumptions.sort((a, b) => {
      if (a.source < b.source) return -1;
      if (a.source > b.source) return 1;
      return 0;
    })

    const specialAssumptions: ValuationAssumptionDeparture[] = [];
    each(state.special.entities, el => specialAssumptions.push(el));
    specialAssumptions.sort((a, b) => {
      if (a.source < b.source) return -1;
      if (a.source > b.source) return 1;
      return 0;
    })

    const departures: ValuationAssumptionDeparture[] = [];
    each(state.departure.entities, el => departures.push(el));
    departures.sort((a, b) => {
      if (a.source < b.source) return -1;
      if (a.source > b.source) return 1;
      return 0;
    })

    items.push(...defaultAssumptions);
    items.push(...specialAssumptions);
    items.push(...departures);

    return items;
  }
)
export const selectNewSpecialAssumptions = createSelector(
  selectAllAssumptionDeparture,
    assumptions => {
        return assumptions.filter(a => a.type == Type.SpecialAssumption && a.source == Source.Input && a.status != Status.Removed)
    }
)