import { HeadlineRent } from "../../types";
import { calculateHeadlineRent } from "./_headline-rent";
import { convertNaNToUndefined, roundResult } from "./_utils";

export function calculateStraightLineMethod(headlineRent: HeadlineRent, size: number) {
  const headlineRentValue = calculateHeadlineRent(
    headlineRent.rentInputAmount,
    headlineRent.rentInputAmounType,
    headlineRent.rentBasis?.id,
    size
  )

  const receivedFor = headlineRent.duration - headlineRent.rentFreePeriod;
  const capitalValueOfHeadlineRent = headlineRentValue * receivedFor;
  const capitalValueOfInducements = capitalValueOfHeadlineRent - headlineRent.capitalPayment;
  const spreadOver = headlineRent.duration - headlineRent.fittingOutPeriod;
  const effectiveRent = capitalValueOfInducements / spreadOver;

  const result = {
    headlineRentValue, receivedFor, capitalValueOfHeadlineRent, capitalValueOfInducements, spreadOver, effectiveRent
  };

  const rounded = roundResult(result)
  const converted = convertNaNToUndefined(rounded)
  return converted
}