import { createAction, props } from "@ngrx/store";
import { ValuationAssumptionDeparture } from "../types";
import { AssetClassModel } from "src/app/core/asset_class";

export const addAssumptionOrDeparture = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Add Assumption or Departure',
  props<{
    data: ValuationAssumptionDeparture
  }>()
)

export const editAssumptionOrDeparture = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Edit Assumption or Departure',
  props<{
    data: ValuationAssumptionDeparture
  }>()
)

export const removeToeAssumptionOrDeparture = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Remove TOE Assumption or Departure',
  props<{
    data: ValuationAssumptionDeparture,
    tp: AssetClassModel,
    justification: string
  }>()
)

export const removeInputAssumptionOrDeparture = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Remove Input Assumption or Departure',
  props<{
    data: ValuationAssumptionDeparture
  }>()
)

export const restoreAssumptionOrDeparture = createAction(
  '[Valuation Process Page - V2 / Preliminiraries Tab] Restore Assumption or Departure',
  props<{
    data: ValuationAssumptionDeparture,
    tp: AssetClassModel
  }>()
)
