import { Component, Input, OnInit } from '@angular/core';
import { Behavior } from 'popper.js';
import { BehaviorSubject } from 'rxjs';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { AssignmentModel } from 'src/app/core/assignment';
import { ReportingProcessData } from 'src/app/core/reporting/_models/reporting_process.model';
import { ToeModel } from 'src/app/core/toe';
import { SupportingValuerModel } from 'src/app/core/toe/_models/supporting-valuer.model';

@Component({
  selector: 'kt-valuation-info',
  templateUrl: './valuation-info.component.html',
  styleUrls: ['./valuation-info.component.scss']
})
export class ValuationInfoComponent implements OnInit {
  @Input() tp: AssetClassModel;
  @Input() toe: ToeModel;
  @Input() valuation: ValuationModel;
  @Input() workers: SupportingValuerModel[];
  @Input() assignment: AssignmentModel;
  @Input() reporting: ReportingProcessData;

  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor() { }

  ngOnInit(): void {
    this.loading$.next(false);
  }

  showIncomeSummary(valuation: ValuationModel) {
    return valuation.approaches_to_value_id == 2
  }

}
