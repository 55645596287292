import * as moment from "moment";
import { ConvertedCostRevenue } from "../../types/converted-cost-revenue";
import { ModelAssumptionsUtil } from "./model-assumptions-util";
import {
  totalRecoverable as calculateTR,
  totalNonRecoverable as calculateTNR
} from '../../utils'
import { miniFs, rounddown4, yieldPurchaseOnPerpetuityAnnually, yieldPurchaseOnPerpetuityQuarterly, yieldPurchaseUntilReviewAnnually, yieldPurchaseUntilReviewQuarterly } from "./utils";

export function calculateTermAndReversionHardcoreFroth(
  effectiveRent: number,
  costRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {timingId, allRiskYield, marketRent, activeBreakOffOption, purchaserCost, reversionaryYield}: {
    activeBreakOffOption: boolean,
    timingId: number,
    allRiskYield: number,
    marketRent: number,
    purchaserCost: number,
    reversionaryYield: number
  }
) {
  const today = moment(valuationDate)
  const unexpiredLeaseTerm = today ? ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, today) : null
  const yearsToReview = today ? ModelAssumptionsUtil.yearsToReview(costRevenue, today) : null
  const yearsToWriteOff = today ? ModelAssumptionsUtil.yearsToWriteOff(costRevenue, today) : null
  const yearsToBreakOff = today ? ModelAssumptionsUtil.yearsToBreakOff(costRevenue, today) : null

  const totalRecoverable = calculateTR(costRevenue) * 12
  const totalNonRecoverable = calculateTNR(costRevenue) * 12
  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const yieldPurchaseOnPerpetuity = timingId == 1
    ? yieldPurchaseOnPerpetuityAnnually(allRiskYield)
    : timingId == 2
      ? yieldPurchaseOnPerpetuityQuarterly(allRiskYield)
      : undefined
  const capitalValueOfTerm = yieldPurchaseOnPerpetuity * marketRent 

  const excessRent = passingRent - marketRent
  const minYear = miniFs(
    activeBreakOffOption
      ? {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff, 'Years to Break off': yearsToBreakOff}
      : {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff}
  )
  const yieldPurchaseUntilReview = timingId == 1 
    ? yieldPurchaseUntilReviewAnnually(reversionaryYield, minYear.value)
    : timingId == 2
      ? yieldPurchaseUntilReviewQuarterly(reversionaryYield, minYear.value)
      : undefined
  const capitalValueOfExcessRent = yieldPurchaseUntilReview * excessRent

  const capitalValueOfTermAndExcess = capitalValueOfTerm + capitalValueOfExcessRent
  const totalPurchaserCost = capitalValueOfTermAndExcess
    ? purchaserCost
      ? (purchaserCost / 100) * capitalValueOfTermAndExcess
      : 0
    : undefined
  const value = capitalValueOfTermAndExcess - totalPurchaserCost

  return {
    unexpiredLeaseTerm,
    yearsToReview,
    yearsToWriteOff,
    yearsToBreakOff,

    effectiveRent,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * 12,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * 12, 
      recoverableUtilities: costRevenue.recoverableUtilities * 12,
      recoverableMaintenance: costRevenue.recoverableMaintenance * 12,
      recoverableInsurance: costRevenue.recoverableInsurance * 12,
      recoverableJanitorial: costRevenue.recoverableJanitorial * 12,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * 12,
      recoverableBusiness: costRevenue.recoverableBusiness * 12,
      recoverableOthers: costRevenue.recoverableOthers * 12,
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * 12,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * 12, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * 12,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * 12,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * 12,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * 12,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * 12,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * 12,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * 12,
    },
    passingRent,

    yieldPurchaseOnPerpetuity,
    capitalValueOfTerm,

    excessRent,
    yieldPurchaseUntilReview: {
      value: yieldPurchaseUntilReview,
      year: minYear
    },
    capitalValueOfExcessRent,

    capitalValueOfTermAndExcess,
    totalPurchaserCost,
    value
  }
}