import { ValuationProcessConsiderationCriterionActions, ValuationProcessConsiderationCriterionActionTypes } from "../_actions/consideration-criterion.actions"
import { ConsiderationCriterionModel, ConsiderationValue, createConsiderationConvertionData } from "../_models/valuation-process-criterion.model"
import * as _ from 'lodash'
import { VComparable } from "../_models"
import { VAssetClassConsideration, VTargetPropertyConsideration } from "../_models/valuation-asset-class-common.models"
import { ConsiderationConversionProcess } from "../../valuation/_models/criterion.model"
import { calculateValuation } from "../../v2/valuation"

export type ValuationProcessConsiderationCriterionState = {
    item: ConsiderationCriterionModel
}
const initialValuationProcessConsiderationCriterionState: ValuationProcessConsiderationCriterionState = {
    item: null
}
export function valuationProcessConsiderationCriterionReducer(
    state = initialValuationProcessConsiderationCriterionState,
    action: ValuationProcessConsiderationCriterionActions
): ValuationProcessConsiderationCriterionState {
    switch (action.type) {
        case ValuationProcessConsiderationCriterionActionTypes.LoadCriterion: {
            let values = action.payload.crit.values;
            if (Array.isArray(values) && values.length == 0) {
                values = {}
            }
            const oldCrit: any = action.payload.crit
            return {
                item: {
                    ...action.payload.crit,
                    values,
                    considerationType: action.payload.crit.considerationType
                        ? action.payload.crit.considerationType
                        :  oldCrit.tenure 
                            ? oldCrit.tenure === 'Freehold'
                                ? 'Sale'
                                : 'Rent'
                            : 'Sale'
                }
            }
        }
        case ValuationProcessConsiderationCriterionActionTypes.CreateCriterion: {
            const considerationType = getConsiderationType(action.payload.considerationType)
            return {
                ...state,
                item: {
                    name: 'Consideration',
                    publicName: action.payload.tenure,
                    values: {},
                    type: 'Consideration',
                    considerationType,
                    categoryName: getCategoryName(considerationType, action.payload.countryCurrency),
                    countryCurrency: action.payload.countryCurrency
                }
            }
        }
        case ValuationProcessConsiderationCriterionActionTypes.AddComparable: {
            const values = _.cloneDeep(state.item.values)
            values[action.payload.comparable.refNum] = addComparable(action.payload.comparable, action.payload.selectedConsiderationId, state.item.considerationType)
            return {
                item: {
                    ...state.item,
                    values: values
                }
            }
        }
        case ValuationProcessConsiderationCriterionActionTypes.AddMultipleComparables: {
            let values = action.payload.adjustmentTabData.considerationCrit.values;
            if (Array.isArray(values) && values.length == 0) {
                values = {}
            }
            return {
                item: {
                    ...action.payload.adjustmentTabData.considerationCrit,
                    values
                }
            }
        }
        case ValuationProcessConsiderationCriterionActionTypes.RemoveComparable: {
            const values = _.cloneDeep(state.item.values)
            delete values[action.payload.refNum]
            return {
                item: {
                    ...state.item,
                    values: values
                }
            }
        }
        case ValuationProcessConsiderationCriterionActionTypes.UpdateCriterionValue: {
            const values = _.cloneDeep(state.item.values)
            const value = values[action.payload.refNum]
            if (value.kind != 'no process') {
                value.value = action.payload.value
                value.considerationConvertionData = action.payload.convertionData
            }
            return {
                item: {
                    ...state.item,
                    values
                }
            }
        }
        case ValuationProcessConsiderationCriterionActionTypes.UpdateCriterionValueBasedOnSize: {
            const values = _.cloneDeep(state.item.values)
            if (action.payload.refNum == 'tp') {
                return {
                    item: {
                        ...state.item,
                        values
                    }
                }
            }
            const value = values[action.payload.refNum]
            values[action.payload.refNum] = calculateValuation(value, action.payload.size)
            return {
                item: {
                    ...state.item,
                    values
                }
            }
        }
        default:
            return state
    }
}

function getConsiderationType(name: string): VTargetPropertyConsideration['considerationType'] {
    switch (name) {
        case 'Sale': return 'Sale'
        case 'Rent': return 'Rent'
        default: return 'Sale'
    }
}

function getCategoryName(tenure: VTargetPropertyConsideration['considerationType'], countryCurrency: string): string {
    switch (tenure) {
        case 'Sale': {
            return `Consideration (${countryCurrency}) (Capital Value) `
        }
        case 'Rent': {
            return `Consideration (${countryCurrency}) (Rental Value - per year)`
        }
    }
}

function addComparable(comparable: VComparable, considerationId: number, tenure: VTargetPropertyConsideration['considerationType']): ConsiderationValue {
    if (comparable.considerations.length == 0) {
        return {
            kind: 'no process',
            value: null,
            consideration: null
        }
    }
    if (considerationId == null) {
        return considerationValueCreator(comparable.considerations[0], tenure)
    }
    const consideration = comparable.considerations.find(consideration => consideration.id == considerationId)
    if (!consideration) {
        return {
            kind: 'no process',
            value: null,
            consideration: null
        }
    }
    return considerationValueCreator(consideration, tenure)
}

function considerationValueCreator(consideration: VAssetClassConsideration, tenure: VTargetPropertyConsideration['considerationType']): ConsiderationValue {
    switch (tenure) {
        case 'Sale': {
            return considerationToSale(consideration)
        }
        case 'Rent': {
            return considerationToRent(consideration)
        }
        default: {
            return {
                kind: 'no process',
                value: consideration.value,
                consideration,
            }
        }
    }
}

function considerationToSale(consideration: VAssetClassConsideration): ConsiderationValue {
    switch (consideration.considerationType) {
        case 'Sale':
            return { kind: 'no process', value: consideration.value, consideration }
        case 'Rent': {
            switch (consideration.rentType) {
                case 'Effective': return { kind: 'rent-to-sale', value: null, consideration: consideration, considerationConvertionData: createConsiderationConvertionData(ConsiderationConversionProcess.LeaseholdToFreehold) }
                case 'Headline': return { kind: 'headline-to-effective-to-rent-to-sale', value: null, consideration: consideration, considerationConvertionData: createConsiderationConvertionData(ConsiderationConversionProcess.HeadlineToEffectiveToLeaseholdToFreehold) }
            }
        }
        case 'Land': {
            return { kind: 'no process', value: consideration.value, consideration }
        }
    }
}

function considerationToRent(consideration: VAssetClassConsideration): ConsiderationValue {
    switch (consideration.considerationType) {
        case 'Sale':
            return { kind: 'sale-to-rent', value: null, consideration, considerationConvertionData: createConsiderationConvertionData(ConsiderationConversionProcess.FreeholdToLeasehold) }
        case 'Rent': {
            switch (consideration.rentType) {
                case 'Effective': return { kind: 'no process', value: consideration.value * 12, consideration }
                case 'Headline': return { kind: 'headline-to-effective', value: null, consideration, considerationConvertionData: createConsiderationConvertionData(ConsiderationConversionProcess.HeadlineToEffective) }
            }
        }
        case 'Land': {
            return { kind: 'no process', value: consideration.value, consideration }
        }
    }
}