import { AssetClassTenure } from "../../comparable";

export function calculateRecoverable(consideration: AssetClassTenure): number {
  let total = 0;
  if (consideration.recoverable_property_management) {
    total += consideration.recoverable_property_management
  }
  if (consideration.recoverable_utilities) {
    total += consideration.recoverable_utilities
  }
  if (consideration.recoverable_insurance) {
    total += consideration.recoverable_insurance
  }
  if (consideration.recoverable_property_taxes) {
    total += consideration.recoverable_property_taxes
  }
  if (consideration.recoverable_leasing_expenses) {
    total += consideration.recoverable_leasing_expenses
  }
  if (consideration.recoverable_maintenance) {
    total += consideration.recoverable_maintenance
  }
  if (consideration.recoverable_janitorial) {
    total += consideration.recoverable_janitorial
  }
  if (consideration.recoverable_business) {
    total += consideration.recoverable_business
  }
  if (consideration.recoverable_others) {
    total += consideration.recoverable_others
  }
  return total
}

export function calculateNonRecoverable(consideration: AssetClassTenure): number {
  let total = 0;
  if (consideration.non_recoverable_property_management) {
    total += consideration.non_recoverable_property_management
  }
  if (consideration.non_recoverable_utilities) {
    total += consideration.non_recoverable_utilities
  }
  if (consideration.non_recoverable_insurance) {
    total += consideration.non_recoverable_insurance
  }
  if (consideration.non_recoverable_property_taxes) {
    total += consideration.non_recoverable_property_taxes
  }
  if (consideration.non_recoverable_leasing_expenses) {
    total += consideration.non_recoverable_leasing_expenses
  }
  if (consideration.non_recoverable_maintenance) {
    total += consideration.non_recoverable_maintenance
  }
  if (consideration.non_recoverable_janitorial) {
    total += consideration.non_recoverable_janitorial
  }
  if (consideration.non_recoverable_business) {
    total += consideration.non_recoverable_business
  }
  if (consideration.non_recoverable_others) {
    total += consideration.non_recoverable_others
  }
  return total
}